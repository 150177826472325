import axiosIns from '@axios'

const $http = axiosIns

export const $getToken = async (payload: object) => {
  return $http.post('auth/token/login/', payload)
}

// Profile
export const $getMe = async () => {
  return $http.get('auth/users/me/')
}

export const $profileUpdate = async (payload: object) => {
  return await $http.patch(`/profile/update/`, payload)
}

// Change password
export const $profileUpdatePassword = async (payload: object) => {
  return await $http.post(`auth/users/set_password/`, payload)
}

// Reset password
export const $profileResetPassword = async (payload: object) => {
  return await $http.post(`auth/users/reset_password/`, payload)
}

export const $profileResetPasswordConfirm = async (payload: object) => {
  return await $http.post(`auth/users/reset_password_confirm/`, payload)
}
