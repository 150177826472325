<script setup lang="ts">
import { VDataTableServer } from 'vuetify/labs/VDataTable'
import { useDisplay } from 'vuetify'
import {
  ChangeRequest,
  ChangeRequestTableOptions,
  ChangeRequestTableSorting,
  RowClickEvent
} from '@/models/changeRequest'
import { $getChangeRequests } from '@/api/changeRequests'

const vuetifyDisplay = useDisplay

const router = useRouter()

const changeRequestTableHeaders = [
  { key: 'code', title: 'Code', sortable: false },
  { key: 'modified_display', title: 'Date & Time', sortable: false },
  { key: 'requestor_name', title: 'Requestor Name', sortable: true },
  { key: 'requestor_email', title: 'Requestor Email', sortable: false },
  { key: 'status_display', title: 'Status', sortable: false },
  { key: 'assigned_to_name', title: 'Assigned To', sortable: false },
  { key: 'request_type_display', title: 'Change Request Type', sortable: false },
  { key: 'project_type_display', title: 'Type of Project', sortable: false },
  { key: 'project_name', title: 'Project', sortable: false },
  { key: 'item_code', title: 'Item Code', sortable: false },
  { key: 'details', title: 'Details', sortable: false },
  { key: 'services_required', title: 'Services Required', sortable: false },
  { key: 'file_attachments', title: 'File Attachments', sortable: false },
  { key: 'actions', title: '', sortable: false },
]

const changeRequestTableOptions = ref<ChangeRequestTableOptions>({
  itemsPerPage: 10,
  page: 1,
  sortBy: []
})

const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 15, title: '15' },
  { value: 20, title: '20' },
  { value: 50, title: '50' },
]

const isMounted = ref<boolean>(false)

const changeRequests = ref<ChangeRequest[]>([])
const changeRequestsCount = ref<number>(0)
const changeRequestsLoading = ref<boolean>(false)

const gerOrdering = () => {
  // Define default ordering
  let ordering = ''

  // Check if sortBy is defined and has at least one item
  if (changeRequestTableOptions.value.sortBy.length > 0) {
    const sortBy = changeRequestTableOptions.value.sortBy[0]
    const sortByKey = sortBy.key
    const sortOrder = sortBy.order === 'desc' ? '-' : '' // Determine the sorting order

    ordering = `${sortByKey}, -id`

    ordering = `${sortOrder}${ordering}`
  }

  return ordering
}

const getChangeRequests = async () => {
  changeRequestsLoading.value = true

  const ordering = gerOrdering()
  try {
    const res = await $getChangeRequests({
      ordering: ordering,
      page: changeRequestTableOptions.value.page,
      per_page: changeRequestTableOptions.value.itemsPerPage,
      action_required: true
    })
    changeRequests.value = res.data.results
    changeRequestsCount.value = res.data.count
  } catch (e: any) {
    // If page doesn't exist goes to 1 page
    if (e?.response?.status === 404) {
      changeRequestTableOptions.value.page = 1
      await getChangeRequests()
    } else {
      console.log(e)
    }
  } finally {
    changeRequestsLoading.value = false
  }
}

onMounted(() => {
  getChangeRequests()

  isMounted.value = true
})

const onUpdateTableOptions = async ({ page, itemsPerPage, sortBy }: { page: number; itemsPerPage: number; sortBy: ChangeRequestTableSorting[] }) => {
  changeRequestTableOptions.value.page = page
  changeRequestTableOptions.value.itemsPerPage = itemsPerPage
  changeRequestTableOptions.value.sortBy = sortBy
  if (isMounted.value) {
    await getChangeRequests()
  }
}

const onRowClick = async (e: MouseEvent, payload: RowClickEvent) => {
  const { item } = payload
  // View Change Request
  await router.push({ path: `/change-requests/${item.raw.id}` })
}
</script>

<template>
  <VCard title="Change Request Items Assigned To You">
    <VCardText>
      <VOverlay
        v-model="changeRequestsLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VDataTableServer
        v-model:page="changeRequestTableOptions.page"
        :items="[ ...changeRequests ]"
        :items-length="parseInt(String(changeRequestsCount), 10)"
        :headers="changeRequestTableHeaders"
        :loading="!!changeRequestsLoading"
        no-data-text="Change Requests not found"
        density="compact"
        hover
        show-current-page
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page="changeRequestTableOptions.itemsPerPage"
        @update:options="onUpdateTableOptions"
        @click:row="onRowClick"
      >
        <template #item.services_required="{ item }">
          {{ item.raw.services_required.map(i => i.charAt(0).toUpperCase() + i.slice(1)).join(', ') }}
        </template>
        <template #item.file_attachments="{ item }">
          <a
            v-for="file in item.raw.file_attachments"
            :key="file.id"
            :href="file.file"
            target="_blank"
            @click.stop
          >
            <VIcon
              icon="tabler-file-description"
            />
          </a>
        </template>
        <template #item.actions="{ item }">
        </template>
      </VDataTableServer>
    </VCardText>
  </VCard>
</template>
