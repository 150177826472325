import { defineStore } from 'pinia'
import { SnackbarMessagePayload } from '@/store/models/snackbar'

export const useSnackbarStore = defineStore({
  id: 'snackbar',
  persist: false,
  state: () => ({
    showSnackbar: false,
    // color: success - "success", error - "error",
    color: 'success',
    timeout: 3000,
    // icon: success - "mdi-checkbox-marked-circle", error - "mdi-alert-circle",
    icon: 'mdi-checkbox-marked-circle',
    title: 'Success',
    text: 'Success'
  }),

  actions: {
    showMessage (payload: SnackbarMessagePayload) {
      this.color = payload.color
      this.timeout = payload.timeout ? payload.timeout : 3000
      this.icon = payload.icon
      this.title = payload.title
      this.text = payload.text
      this.showSnackbar = true
    },
  },
})
