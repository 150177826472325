<script setup lang="ts">
import { $createSignComment } from '@/api/signs'
import { useSnackbarStore } from '@/store/snackbar'
import { TagListUser } from '@/models/user'
import { $userList } from '@/api/users'
import { uniqBy } from 'lodash'

const snackbarStore = useSnackbarStore()
interface IProps {
  signId?: number | null,
}

const props = withDefaults(defineProps<IProps>(), {
  signId: null,
})

const emit = defineEmits(['onAddComment'])

const placeholder = 'Write a comment...'

const editableDiv = ref<HTMLDivElement | null>(null)
const editableDivLastCaretPosition = ref<number>(0)
const message = ref<string>('')
const previousTextareaValue = ref<string>('')

const isFocused = ref<boolean>(false)

const isMounted = ref<boolean>(false)

const menuOpen = ref<boolean>(false)

const selectedUserId = ref<string | null>(null)

const users = ref<TagListUser[]>([])
const usersCurrentPage = ref<number>(1)
const usersPerPage: number = 10
const usersSearchStr = ref<string>('')
const isNextUsersPageExist = ref<boolean>(false)
const usersLoading = ref<boolean>(false)

const selectedUsersFullList = ref<TagListUser[]>([])

const addBtnLoading = ref<boolean>(false)

const handleInput = () => {
  message.value = editableDiv.value?.innerText || ''

  const currentTextareaValue = message.value

  // Check if the previous value is longer than the current value
  if (previousTextareaValue.value.length > currentTextareaValue.length) {
    // Handle the caret position after deletion
    handleCaretPosition()
  }

  // Update the previous value to the current value for the next comparison
  previousTextareaValue.value = currentTextareaValue
}

const getUsers = async () => {
  usersLoading.value = true
  const filters = {
    get_all: true,  // Get all users, regardless of role
    tag_list: true, // Fields: 'id', 'display_name', 'email'
    tag_list_sign_id: props.signId, // Filtering by TLC Members/Customer/Team Members
    search: usersSearchStr.value,
    per_page: usersPerPage,
    page: usersCurrentPage.value,
  }
  try {
    const res = await $userList(filters)

    // Append the new users to the existing list
    // Use Lodash's uniqBy to merge arrays while ensuring uniqueness based on 'id'
    users.value = uniqBy([...users.value, ...res.data.results], 'id')

    isNextUsersPageExist.value = !!res.data.next
  }
  catch (e) {
    console.log(e)
  }
  finally {
    usersLoading.value = false
  }
}

const onLoadMoreUsers = async () => {
  usersCurrentPage.value++ // Increment the current page to load the next page of users
  await getUsers()
}

const handleUserSearch = useDebounceFn((searchStr: string) => {
  // If search the same - don't fetch users
  if (usersSearchStr.value !== searchStr || !users.value.length) {
    users.value = []
    usersCurrentPage.value = 1 // Set current page => 1 to load initial first page of users
    usersSearchStr.value = searchStr
    getUsers()
  }
}, 500)

const selectUser = (user: TagListUser) => {
  const userId = user?.id

  const messageInput = document.querySelector('#activity-timeline-comment-custom-input') as HTMLDivElement

  const { startIndex, endIndex } = getCaretWordIndexes(editableDivLastCaretPosition.value, true)

  messageInput.innerHTML = messageInput.innerHTML.slice(0, startIndex) +
    `<span class="tagged-user-in-comment" data-value="${userId}" contenteditable="false">${user?.display_name?.replace(/<[^>]*>/g, '')?.trim()}</span>`
    + messageInput.innerHTML.slice(endIndex)

  // return caret to messageInput
  placeCaretAtEnd(messageInput)

  message.value = messageInput.innerText

  selectedUsersFullList.value = uniqBy([...selectedUsersFullList.value, user], 'id')

  menuOpen.value = false
}

const getIsCaretOnTagWord = () => {
  const { startIndex, endIndex } = getCaretWordIndexes()

  const firstChar = message.value.charAt(startIndex)
  const isFirstCharAt = firstChar === '@'

  // if caret position after @ or on word which starts with @ -> caret on tag word
  return {
    isCaretOnTagWord: isFirstCharAt
  }
}

const handleCaretPosition = () => {
  const { isCaretOnTagWord } = getIsCaretOnTagWord()

  // If input is active
  if (isFocused.value) {
    editableDivLastCaretPosition.value = getCaretPosition()
    // if @ word - show menu(user list)
    if (isCaretOnTagWord) {
      menuOpen.value = true

      const { startIndex, endIndex } = getCaretWordIndexes()
      const caretWord = message.value.substring(startIndex, endIndex).substring(1)

      selectedUserId.value = caretWord

      handleUserSearch(caretWord)
    } else {
      menuOpen.value = false
      selectedUserId.value = null
    }
  }
}

const getCaretPosition = () => {
  if (!editableDiv.value) return -1

  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    const preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(editableDiv.value)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    return preCaretRange.toString().length
  }

  return -1 // Caret position not available
}

const placeCaretAtEnd = (el: HTMLDivElement) => {
  el.focus()
  if (typeof window.getSelection != "undefined"
    && typeof document.createRange != "undefined") {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  } else if (typeof document.body.createTextRange != "undefined") {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

// Function to set the caret position
const setCaretPosition = (position: number) => {
  const messageInput = document.querySelector('#activity-timeline-comment-custom-input') as HTMLDivElement

  if (messageInput) {
    // Create a range object
    let range = document.createRange()

    // Create a selection object
    let selection = window.getSelection()

    if (selection) {
      // Set the range to the entire contents of the div
      range.selectNodeContents(messageInput)

      // Get the child nodes of the div
      let childNodes = messageInput.childNodes

      // Iterate over child nodes to find the appropriate node to set the range start
      let currentOffset = 0
      for (let i = 0; i < childNodes.length; i++) {
        let node = childNodes[i]
        if (node.nodeType === Node.TEXT_NODE) {
          let nodeLength = (node as Text).textContent?.length || 0
          if (position <= currentOffset + nodeLength) {
            range.setStart(node, position - currentOffset)
            range.collapse(true)
            break
          }
          currentOffset += nodeLength
        }
      }

      // Remove any existing selections
      selection.removeAllRanges()

      // Add the new selection with the collapsed range
      selection.addRange(range)
    }
  }
}

const convertInnerTextToInnerHTMLCaretPosition = (innerTextCaretPosition: number, div: HTMLDivElement): number => {
  const innerText = div.innerText
  const innerHTML = div.innerHTML

  let innerTextIndex = 0
  let innerHTMLIndex = 0

  while (innerTextIndex < innerText.length && innerHTMLIndex < innerHTML.length) {
    const innerTextChar = innerText[innerTextIndex]
    const innerHTMLChar = innerHTML[innerHTMLIndex]

    if (innerTextChar === innerHTMLChar) {
      innerTextIndex++
      innerHTMLIndex++
    }
    else if (innerHTMLChar === '<') {
      while (innerHTML[innerHTMLIndex] !== '>') {
        innerHTMLIndex++
      }
      innerHTMLIndex++
    }
    else if (innerHTML[innerHTMLIndex] === '&') {
      if (innerHTML.substring(innerHTMLIndex, innerHTMLIndex + 6) === '&nbsp;') {
        innerHTMLIndex += 6 // skipping '&nbsp;' in innerText
        innerTextIndex++
      } else if (innerText[innerTextIndex] === innerHTML[innerHTMLIndex]) {
        innerTextIndex++
        innerHTMLIndex++
      } else {
        innerHTMLIndex++
      }
    }
    else {
      innerHTMLIndex++
    }

    if (innerTextIndex === innerTextCaretPosition) {
      return innerHTMLIndex
    }
  }

  return -1
}

const getCaretWordIndexes = (lastCaretPosition: number | null = null, isHTML: boolean = false) => {
  let caretPosition = lastCaretPosition ? lastCaretPosition : getCaretPosition()

  let text = message.value

  if (isHTML) {
    const messageInput = document.querySelector('#activity-timeline-comment-custom-input') as HTMLDivElement
    caretPosition = convertInnerTextToInnerHTMLCaretPosition(caretPosition, messageInput)
    text = messageInput.innerHTML.replace(/&nbsp;/g, '      ')
  }

  let start = caretPosition
  while (start > 0 && !/\s/.test(text.charAt(start - 1))) {
    start--
  }

  let end = caretPosition
  while (end < text.length && !/\s/.test(text.charAt(end))) {
    end++
  }

  if (!/\s/.test(text.charAt(start))) {
    while (start > 0 && !/\s/.test(text.charAt(start - 1))) {
      start--
    }
  }

  if (!/\s/.test(text.charAt(end - 1))) {
    while (end < text.length && !/\s/.test(text.charAt(end))) {
      end++
    }
  }

  let word = text.substring(start, end)

  return { startIndex: start, endIndex: end }
}

const handleOutsideClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement
  const bottomCardElement = document.getElementById('sign-modal-timeline-sidebar-bottom-card')
  if (bottomCardElement && !bottomCardElement.contains(target)) {
    menuOpen.value = false
  }
}

onMounted(() => {
  isMounted.value = true
  document.addEventListener('click', handleOutsideClick)
  document.addEventListener('selectionchange', handleCaretPosition)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleOutsideClick)
  document.removeEventListener('selectionchange', handleCaretPosition)
})

const parseInnerHTMLToPayload = (innerHTML: string): string => {
  let parser = new DOMParser()
  let doc = parser.parseFromString(innerHTML, 'text/html')
  let elements = doc.body.childNodes

  let result = ''

  elements.forEach(element => {
    if (element.nodeType === 3) { // Text node
      result += (element.textContent as string).replace(/\s+/g, ' ')
    } else if (element.nodeType === 1 && (element as HTMLElement).tagName === 'SPAN') { // Span node
      let dataValue = (element as HTMLElement).getAttribute('data-value')
      if (dataValue) {
        result += `@${dataValue} `
      }
    }
  })

  // Trim extra spaces from the whole result
  return result.trim().replace(/\s+/g, ' ')
}

const addComment = async () => {
  if (props.signId) {
    addBtnLoading.value = true

    const messageInput = document.querySelector('#activity-timeline-comment-custom-input') as HTMLDivElement
    try {
      await $createSignComment({
        sign: props.signId,
        comment: parseInnerHTMLToPayload(messageInput.innerHTML)
      })

      snackbarStore.showMessage({
        color: 'success',
        icon: 'mdi-checkbox-marked-circle',
        title: 'Success',
        text: 'Sign comment added successfully!'
      })
    } catch (e) {
      console.log(e)
      const errorData = JSON.parse(JSON.stringify(e?.response?.data))
      const firstErrorKey = Object.keys(errorData)[0]

      snackbarStore.showMessage({
        color: 'error',
        icon: 'mdi-alert-circle',
        title: 'Error',
        text: `Oops, something went wrong, try again. ${errorData[firstErrorKey]}`
      })
    } finally {
      message.value = ''
      if (messageInput) {
        messageInput.innerHTML = ''
      }
      emit('onAddComment')
      addBtnLoading.value = false
    }
  } else {
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: `Oops, something went wrong, try again. Sign ID not found.`
    })
  }
}
</script>

<template>
  <div
    id="sign-comment-input-block"
  >
    <VList
      v-if="menuOpen"
      dense
      max-height="340"
    >
      <VListItem
        v-for="user in users"
        :key="user.id"
        :active="selectedUserId.toString() === user?.id?.toString()"
        :disabled="!!usersLoading || !!addBtnLoading"
        @click.stop="selectUser(user)"
      >
        {{ user.display_name }}
      </VListItem>
      <VListItem
        v-if="isNextUsersPageExist"
        :disabled="!!usersLoading || !!addBtnLoading"
        @click="onLoadMoreUsers"
      >
        Load more...
        <VProgressCircular
          v-if="!!usersLoading"
          class="ml-2"
          size="16"
          width="3"
          indeterminate
          color="white"
        />
      </VListItem>
      <VListItem
        v-if="!users.length"
      >
        <VOverlay
          v-model="usersLoading"
          persistent
          absolute
          contained
          class="align-center justify-center"
        >
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </VOverlay>
        Users not found
      </VListItem>
    </VList>
    <div
      class="custom-textarea"
    >
      <div class="prepend-inner">
        <div>
          <VIcon
            size="small"
            icon="tabler-info-circle"
          />
          <VMenu
            activator="parent"
            location="top center"
            open-on-hover
            open-delay="100"
            close-delay="0"
            offset="5"
            class="default-tooltip"
          >
          <span class="px-2">
            Use @ + Select User to tag user(s)
          </span>
          </VMenu>
        </div>
      </div>
      <div
        id="activity-timeline-comment-custom-input"
        ref="editableDiv"
        contenteditable="true"
        @input="handleInput"
        @focusin="isFocused = true"
        @focusout="isFocused = false"
      >
      </div>
      <div
        v-if="!message && !isFocused"
        class="placeholder-block"
        @click="editableDiv.focus()"
      >
        {{ placeholder }}
      </div>
      <div class="append-inner">
        <div>
          <VBtn
            size=""
            icon="tabler-send-2"
            class="rounded pa-1"
            color="light"
            variant="text"
            :disabled="!message || !!addBtnLoading"
            @click="addComment"
          >
            <VMenu
              activator="parent"
              location="top center"
              open-on-hover
              open-delay="100"
              close-delay="0"
              offset="5"
              class="default-tooltip"
            >
              <span class="px-1">
                Add
              </span>
            </VMenu>
            <div class="position-relative">
              <VProgressCircular
                v-if="!!addBtnLoading"
                class="add-comment-btn-loader"
                size="24"
                width="2"
                indeterminate
                color="primary"
              />
              <VIcon
                :color="!message ? 'light' : 'primary'"
                icon="tabler-send-2"
              />
            </div>
          </VBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">


.custom-textarea {
  position: relative;

  .prepend-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px;
  }

  .append-inner {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
  }

  .placeholder-block {
    position: absolute;
    top: 5px;
    left: 40px;
    color: #aaa;
  }

  div[contenteditable=true] {
    border: 1px solid #ccc;
    padding: 5px;
    min-height: 42.5px;
    margin-left: 35px; // Adjust according to the width of prepend-inner
    margin-right: 35px; // Adjust according to the width of append-inner
    transition: border-color 0.3s ease;
    border-radius: 5px;

    &:focus {
      outline: none;
      border-color: rgb(var(--v-theme-primary));
      border-radius: 5px;
    }

    .tagged-user-in-comment {
      color: rgb(var(--v-theme-primary)) !important;
      text-decoration: underline;
    }
  }
}
</style>
