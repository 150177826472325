import { reactive, ref, toRefs } from 'vue'

import axiosIns from '@axios'

const $http = axiosIns

export const $getCampaigns = async (params: object) => {
  const data = ref([])
  const count = ref(0)

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get('campaigns/', { params })

    data.value = res.data.results
    count.value = res.data.count
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, count, ...toRefs(state) }
}

export const $getCampaignSimpleList = async (params: object) => {
  const data = ref([])
  const count = ref(0)
  const isNextPageExist = ref(false)

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get('campaign-simple-list/', { params })

    data.value = res.data.results
    count.value = res.data.count
    isNextPageExist.value = res.data.next
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, count, isNextPageExist, ...toRefs(state) }
}

export const $getBrandingCampaignsWithOrdersSimpleList = async (params: object) => {
  const data = ref([])
  const count = ref(0)
  const isNextPageExist = ref(false)

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get('branding-campaign-with-orders-simple-list/', { params })

    data.value = res.data.results
    count.value = res.data.count
    isNextPageExist.value = res.data.next
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, count, isNextPageExist, ...toRefs(state) }
}

export const $getCampaignOptions = async () => {
  return await $http.options('campaigns/')
}

export const $getCampaign = async (id: any) => {
  return await $http.get(`campaigns/${id}/`)
}

export const $createCampaign = async (payload: object) => {
  return await $http.post('campaigns/', payload)
}

export const $updateCampaign = async (id: number | string, payload: object) => {
  return await $http.patch(`campaigns/${id}/`, payload)
}

export const $createDrawing = async (payload: object) => {
  return await $http.post('drawings/', payload)
}

export const $getDrawing = async (filters: object) => {
  return await $http.get('drawings/', { params: filters })
}

export const $updateDrawing = async (id: number, payload: object) => {
  return await $http.patch(`drawings/${id}/`, payload)
}

export const $getCampaignLastDrawings = async (campaignId: number) => {
  return await $http.get(`get-campaign-last-drawings/${campaignId}/`)
}

// Drawing Map
export const $drawingMapSaveItems = async (payload: object) => {
  return await $http.post('drawing-map/save-items/', payload)
}

export const $drawingMapDeleteItems = async (payload: object) => {
  return await $http.post('drawing-map/delete-items/', payload)
}

// Copy Campaign Settings
export const $getCampaignCopySpecificSettings = async (filters: object) => {
  return await $http.get('get-campaign-copy-specific-settings/', { params: filters })
}

// User Campaign Accessibility
export const $getUserCampaignAccessibility = async () => {
  return await $http.get('get-user-campaign-accessibility/')
}
