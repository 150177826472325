<script setup lang="ts">
import { Sign, SignPriority } from '@/models/sign'
import { can } from '@layouts/plugins/casl'

interface IProps {
  sign?: Sign,
  signLoading?: boolean,
  signPriorityOptions?: SignPriority[],
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  signLoading: false,
  priorityOptions: () => ([]),
})

interface CustomEvents {
  (e: 'onSelectPriority', value: number): void
  (e: 'onUpdateSignIsOnHold'): void
  (e: 'update:sign', value: Sign): void
}

const emit = defineEmits<CustomEvents>()

const onSelectPriority = (value: number) => {
  emit('onSelectPriority', value)
}
</script>

<template>
  <VCardText>
    <VRow>
      <VCol class="d-flex align-center flex-wrap justify-space-between">
        <div class="d-flex flex-wrap align-center">
          <VChip
            color="primary"
            variant="outlined"
            class="mr-2 mt-1 mt-sm-0"
          >
            {{ sign?.sign_status_name }}
          </VChip>
          <SignPriorityBlock
            :sign="sign"
            :loading="!!signLoading"
            :priority-options="signPriorityOptions"
            @onSelectPriority="onSelectPriority"
            class="mt-1 mt-sm-0"
          />
        </div>
        <div
          v-if="can('change', 'signonhold')"
          class="d-flex justify-end align-center mt-1 mt-sm-0"
        >
          <VSwitch
            :model-value="sign?.is_on_hold"
            class="default-switch"
            inset
            @update:model-value="newValue => {
            emit('update:sign', { ...sign, is_on_hold: newValue});
            emit('onUpdateSignIsOnHold')
          }"
          >
            <template #label>
              On Hold
            </template>
          </VSwitch>
        </div>
      </VCol>
    </VRow>
  </VCardText>
</template>
