<script setup lang="ts">
import { VDataTableVirtual } from 'vuetify/labs/VDataTable'
import { Sign, SignPriority } from '@/models/sign'

interface IProps {
  sign?: Sign,
  loading?: boolean,
  priorityOptions?: SignPriority[],
}

const props = withDefaults(defineProps<IProps>(), {
  activityTimelineItems: () => ({}),
  loading: false,
  priorityOptions: () => ([]),
})

interface Emit {
  (e: 'onSelectPriority', value: number): void
}
const emit = defineEmits<Emit>()


const priorityColors = ['secondary', 'info', 'warning', 'primary']
</script>

<template>
  <div class="d-flex">
    <VChip
      class="cursor-pointer"
      :color="priorityColors[sign?.priority]"
    >
      {{ sign?.priority_display }}
      <VMenu
        activator="parent"
        location="bottom"
        open-on-click
        close-on-back
        :close-on-content-click="false"
        open-delay="0"
        close-delay="0"
        offset="5"
      >
        <VOverlay
          :model-value="loading"
          persistent
          absolute
          contained
          class="align-center justify-center"
        >
          <VProgressCircular
            indeterminate
            color="primary"
          />
        </VOverlay>
        <VCard
          :loading="!!loading"
          class="border"
          style="width: 200px; height: 210px"
        >
          <div class="pa-2">
            <VDataTableVirtual
              :headers="[{ key: 'display_name', title: 'Priority', sortable: false }]"
              :items="[...priorityOptions]"
              no-data-text="Priorities not found"
              hover
              density="compact"
            >
              <template #item.display_name="{ item }">
                <div
                  :class="{ 'cursor-pointer': item.raw.value !== sign?.priority }"
                  @click="emit('onSelectPriority', item.raw.value)"
                >
                  <VChip
                    :color="priorityColors[item.raw.value]"
                    :variant="item.raw.value === sign?.priority ? 'elevated' : 'outlined'"
                    :class="{ 'cursor-pointer': item.raw.value !== sign?.priority }"
                  >
                    {{ item.raw.display_name }}
                  </VChip>
                </div>
              </template>
            </VDataTableVirtual>
          </div>
        </VCard>
      </VMenu>
    </VChip>
    <div class="d-flex justify-center align-center">
      <VIcon
        size="small"
        icon="tabler-info-circle"
      />
      <VMenu
        activator="parent"
        location="top center"
        open-on-hover
        open-delay="100"
        close-delay="0"
        offset="1"
        class="default-tooltip"
      >
        <span class="px-2">
          Click to change priority
        </span>
      </VMenu>
    </div>
  </div>
</template>

