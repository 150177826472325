<script setup>
import { $deleteSignBlock, $updateSignBlock } from '@/api/signs'

const props = defineProps({
  modelValue: Boolean,
  signBlock: { type: Object, default: () => ({}) },
})

const emit = defineEmits(['update:modelValue', 'update:signBlock', 'onSubmit', 'onClose'])

const closeDialog = () => {
  emit('update:modelValue', !props.modelValue)
}

const handleAction = async (action) => {
  // If there is no data or no ID - close the dialog
  if (!props.signBlock || !props.signBlock.id) {
    closeDialog()
    return
  }

  try {
    if (action === 'update') {
      const { text_1, text_2 } = props.signBlock
      await $updateSignBlock(props.signBlock.id, { text_1, text_2 })
    } else if (action === 'delete') {
      await $deleteSignBlock(props.signBlock.id)
    }

    emit('onSubmit')
    closeDialog()
  } catch (e) {
    console.log(e)
  }
}

const onUpdate = () => handleAction('update')
const onDelete = () => handleAction('delete')

const onClose = async () => {
  emit('onClose')
}
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="680"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard title="Sign Block">
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            sm=""
          >
            <label class="text-uppercase">
              <strong>Language 1</strong>
            </label>
            <br>
            <span>
              {{ signBlock.text_1 }}
            </span>
          </VCol>
          <VCol
            cols="12"
            sm=""
          >
            <label class="text-uppercase">
              <strong>Language 2</strong>
            </label>
            <br>
            <span>
              {{ signBlock.text_2 }}
            </span>
          </VCol>
        </VRow>
        <VRow>
          <VCol
            class="d-flex justify-space-between"
            cols="12"
          >
            <VBtn
              variant="outlined"
              append-icon="mdi-minus-circle"
              @click="onDelete"
            >
              Delete
            </VBtn>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>
