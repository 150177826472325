<script setup lang="ts">
import { $getLocations } from '@/api/locations'
import { uniqBy } from 'lodash'

interface IProps {
  modelValue?: any,
  label?: string,
  noParent?: boolean,
  selectOptions?: any[] | null,
  /**
   * Optional array of location IDs that should be disabled for selection.
   * If null or undefined, no locations will be disabled.
   */
  disabledLocations?: number[] | null,
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  label: 'Location',
  noParent: false,
  selectOptions: null,
  disabledLocations: null
})

interface CustomEvents {
  'update:modelValue': (newValue: any) => void;
}

const emit = defineEmits<CustomEvents>()

interface Location {
  id: number
  name: string
}

const locations = ref<Location[]>([])
const locationsCurrentPage = ref<number>(1)
const locationsPerPage: number = 10
const locationsSearchStr = ref<string>('')
const isNextLocationsPageExist = ref<boolean>(false)
const locationsLoading = ref<boolean>(false)

const locationOptions = computed(() => {
  if (props.selectOptions) {
    return props.selectOptions
  } else {
    return locations.value
  }
})

const getLocations = async () => {
  locationsLoading.value = true

  const { data, error, isNextPage } = await $getLocations({
    search: locationsSearchStr.value,
    per_page: locationsPerPage,
    page: locationsCurrentPage.value,
    is_simple: true, // is_simple - serializer with only 2 fields: 'id', 'name'
    no_parent: props.noParent, // no_parent - locations without parents
  })

  if (error.value) {
    console.log(error.value)
  }
  else {
    // Append the new locations to the existing list
    // Use Lodash's uniqBy to merge arrays while ensuring uniqueness based on 'id'
    locations.value = uniqBy([...locations.value, ...data.value], 'id')
    isNextLocationsPageExist.value = isNextPage.value
  }

  locationsLoading.value = false
}

const onFocusLocationSelect = async () => {
  if (!locations.value.length && !props.selectOptions) {
    locations.value = []
    locationsCurrentPage.value = 1
    await getLocations()
  }
}

const onLoadMoreLocations = async () => {
  locationsCurrentPage.value++ // Increment the current page to load the next page of locations
  await getLocations()
}

const clearAndGetLocations = (searchStr: string) => {
  locations.value = []
  locationsCurrentPage.value = 1 // Set current page => 1 to load initial first page of locations
  locationsSearchStr.value = searchStr
  getLocations()
}

const handleLocationSearch = useDebounceFn((searchStr: string) => {
  if (!props.selectOptions) {
    clearAndGetLocations(searchStr)
  }
}, 500)

defineExpose({ clearAndGetLocations })
</script>

<template>
  <VAutocomplete
    :model-value="modelValue"
    :items="[...locationOptions]"
    return-object
    item-title="name"
    item-value="id"
    :label="label"
    density="compact"
    :no-filter="!selectOptions"
    multiple
    clearable
    @update:modelValue="newValue => $emit('update:modelValue', newValue)"
    @update:focused="onFocusLocationSelect"
    @update:search="handleLocationSearch"
  >
    <template
      v-if="disabledLocations"
      #item="{ item, index, props }"
    >
      <VListItem
        v-bind="props"
        :disabled="!!disabledLocations ? disabledLocations.includes(item?.value) : false"
      />
    </template>
    <template #selection="{ item, index }">
      <div class="d-flex align-center">
        <v-chip v-if="index < 1">
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="text-grey text-caption align-self-center"
        >
          (+{{ modelValue?.length - 1 }} others)
        </span>
      </div>
    </template>
    <template #no-data>
      <div
        v-if="!!locationsLoading"
        class="d-flex justify-center align-center"
      >
        Loading...
        <VProgressCircular
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
      >
        Locations not found
      </div>
    </template>
    <template
      v-if="isNextLocationsPageExist"
      #append-item
    >
      <VBtn
        color="default"
        block
        :disabled="!!locationsLoading"
        @click="onLoadMoreLocations"
      >
        Load more...
        <VProgressCircular
          v-if="!!locationsLoading"
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </VBtn>
    </template>
  </VAutocomplete>
</template>

<style lang="scss">

</style>
