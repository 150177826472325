<script setup lang="ts">
import { ActivityTimelineItem } from '@/models/activity-timeline'
import { TagListUser } from '@/models/user'
import { useSnackbarStore } from '@/store/snackbar'
import {Sign} from "@/models/sign";

const snackbarStore = useSnackbarStore()

interface IProps {
  activityTimelineItems?: ActivityTimelineItem[],
  loading?: boolean,
  showSidebar?: boolean,
  signId?: number | null,
}

const props = withDefaults(defineProps<IProps>(), {
  activityTimelineItems: () => ([]),
  loading: false,
  showSidebar: false,
  signId: null,
})

interface CustomEvents {
  (e: 'update:showSidebar', value: boolean): void
  (e: 'onAdd'): void
}

const emit = defineEmits<CustomEvents>()

const isMounted = ref<boolean>(false)

const message = ref<string>('')

const users = ref<TagListUser[]>([])

const signDialogOffsetTop = computed<number>(() => {
  if (isMounted.value && props.showSidebar) {
    const dialogContentEl = document.querySelector('.sign-modal .v-overlay__content') as HTMLElement
    if (dialogContentEl) {
      return dialogContentEl.offsetTop
    } else {
      return 0
    }
  } else {
    return 0
  }
})

const signDialogOffsetLeft = computed<number>(() => {
  if (isMounted.value && props.showSidebar) {
    const dialogContentEl = document.querySelector('.sign-modal .v-overlay__content') as HTMLElement
    if (dialogContentEl) {
      return dialogContentEl.offsetLeft
    } else {
      return 0
    }
  } else {
    return 0
  }
})

const signDialogOffsetRight = computed<number>(() => {
  if (isMounted.value && props.showSidebar) {
    const dialogContentEl = document.querySelector('.sign-modal .v-overlay__content') as HTMLElement
    if (dialogContentEl) {
      return window.innerWidth - (dialogContentEl.offsetLeft + dialogContentEl.offsetWidth) // Calculate offset right
    } else {
      return 0
    }
  } else {
    return 0
  }
})

const signDialogOffsetBottom = computed<number>(() => {
  if (isMounted.value && props.showSidebar) {
    const dialogContentEl = document.querySelector('.sign-modal .v-overlay__content') as HTMLElement
    if (dialogContentEl) {
      return window.innerHeight - (dialogContentEl.offsetTop + dialogContentEl.offsetHeight) // Calculate offset bottom
    } else {
      return 0
    }
  } else {
    return 0
  }
})

// Computed style for modal sidebar
const modalSidebarStyle = computed(() => {
  // Access Vuetify display through $vuetify
  const instance = getCurrentInstance()
  const displayWidth = instance?.proxy?.$vuetify.display.width || 0

  return {
    width: displayWidth >= 600 ? '400px' : (props.showSidebar ? '100%' : '0'),
    right: props.showSidebar && displayWidth >= 600 ? `-${signDialogOffsetRight.value}px` : (displayWidth >= 600 ? `-300vw` : '0'),
    top: displayWidth >= 600 ? `-${signDialogOffsetTop.value}px` : '0',
    bottom: displayWidth >= 600 ? `-${signDialogOffsetBottom.value}px` : '0',
    height: displayWidth >= 600 ? '100vh' : '100%',
    maxHeight: displayWidth >= 600 ? '100vh' : '100%'
  }
})

// Computed style for bottom card
const bottomCardStyle = computed(() => {
  // Access Vuetify display through $vuetify
  const instance = getCurrentInstance()
  const displayWidth = instance?.proxy?.$vuetify.display.width || 0

  return {
    width: displayWidth >= 600 ? '400px' : (props.showSidebar ? '100%' : '0'),
    right: props.showSidebar && displayWidth >= 600 ? `-${signDialogOffsetRight.value}px` : (displayWidth >= 600 ? `-300vw` : '0'),
    bottom: displayWidth >= 600 ? `${signDialogOffsetBottom.value < 0 ? -signDialogOffsetBottom.value : -signDialogOffsetBottom.value}px` : '0'
  }
})

const scrollSidebarToBottom = () => {
  // Get the element with the class `.modal-sidebar`
  const modalSidebarEl: HTMLElement | null = document.querySelector('.modal-sidebar')

  if (modalSidebarEl) {
    // Scroll to the bottom of the element
    modalSidebarEl.scrollTop = modalSidebarEl.scrollHeight
  }
}

onMounted(() => {
  isMounted.value = true
})

watch(() => props.showSidebar, () => {
  // On Show Sidebar
  if (props.showSidebar) {
    scrollSidebarToBottom()
  }
})

defineExpose({
  scrollSidebarToBottom
})
</script>

<template>
  <div
    class="modal-sidebar"
    :style="modalSidebarStyle"
  >
    <!-- Dialog close btn -->
    <div
      class="modal-sidebar-close-label"
      @click="emit('update:showSidebar', false)"
    >
      Close
    </div>

    <!-- Sidebar Content where 22.5px(top bar) and 42.5px(bottom bar)  -->
    <SignActivityTimeLineCard
      class="sidebar-content-min-height mb-8 rounded-0"
      :activity-timeline-items="activityTimelineItems"
      :loading="loading"
    />

    <VCard
      id="sign-modal-timeline-sidebar-bottom-card"
      :style="bottomCardStyle"
      class="modal-sidebar-bottom-card"
    >
      <SignCommentInput
        :sign-id="signId ? signId : null"
        @onAddComment="emit('onAdd')"
      />
    </VCard>
  </div>
</template>

<style lang="scss">
.modal-sidebar {
  z-index: 2401;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -300vw; /* Initially hide */
  width: 0; /* Initially set width to 0 */
  height: 100%;
  max-height: 100%;
  background-color: #f4f4f4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease, right 0.3s ease; /* Transition width and right properties */
  overflow-y: scroll;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  .sidebar-content-min-height {
    min-height: calc(100% - 22.5px - 42.5px)
  }

  .modal-sidebar-close-label {
    z-index: 1;
    position: sticky;
    padding: 0 5px;
    background-color: #FFFFFF;
    top: 0;
    color: rgb(var(--v-theme-primary));
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .modal-sidebar-bottom-card {
    transition: width 0.3s ease, right 0.3s ease; /* Transition width and right properties */
    position: fixed;
    width: 0;
    bottom: 0;
    right: -300vw; /* Initially hide */
    .add-comment-btn-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); // centering
    }
  }
}
</style>
