import { openDB } from 'idb'

// import Snackbar
import { useDrawingStore } from '@/store/drawing'

const DB_NAME = 'OfflineImagesDB'
const DB_VERSION = 1
const STORE_NAME = 'images'

// Open IndexedDB and create object store if it doesn't exist
const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, { keyPath: 'url' })
    }
  }
})

// Save image Blob in IndexedDB
export async function idbSaveImage(url: string, blob: Blob) {
  const db = await dbPromise
  await db.put(STORE_NAME, { url, blob })
}

// Get image URL from IndexedDB
export async function idbGetImageUrl(url: string): Promise<string | null> {
  const db = await dbPromise
  const result = await db.get(STORE_NAME, url)
  if (result) {
    const blob = result.blob

    const objectUrl = URL.createObjectURL(blob)
    useDrawingStore().offlineModeObjectUrls.push(objectUrl)

    return objectUrl
  }
  return null
}

// Clear all images from IndexedDB
export async function idbClearAllImages() {
  const db = await dbPromise
  await db.clear(STORE_NAME)
}
