<script lang="ts" setup>
import { useSkins } from '@core/composable/useSkins'
import { useThemeConfig } from '@core/composable/useThemeConfig'

// @layouts plugin
import { AppContentLayoutNav } from '@layouts/enums'
import {useUserStore} from "@/store/user";
import CustomerLayout from "@/layouts/components/CustomerLayout.vue";

const DefaultLayoutWithHorizontalNav = defineAsyncComponent(() => import('./components/DefaultLayoutWithHorizontalNav.vue'))
const DefaultLayoutWithVerticalNav = defineAsyncComponent(() => import('./components/DefaultLayoutWithVerticalNav.vue'))

const { width: windowWidth } = useWindowSize()
const { appContentLayoutNav, switchToVerticalNavOnLtOverlayNavBreakpoint } = useThemeConfig()

// ℹ️ This will switch to vertical nav when define breakpoint is reached when in horizontal nav layout
// Remove below composable usage if you are not using horizontal nav layout in your app
switchToVerticalNavOnLtOverlayNavBreakpoint(windowWidth)

const { layoutAttrs, injectSkinClasses } = useSkins()
const userStore = useUserStore()


injectSkinClasses()
</script>

<template>
  <div>
    <template v-if="appContentLayoutNav === AppContentLayoutNav.Vertical">
      <DefaultLayoutWithVerticalNav v-bind="layoutAttrs" />
    </template>
    <template v-else>
      <DefaultLayoutWithHorizontalNav v-bind="layoutAttrs" />
    </template>
  </div>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-layout";
</style>
