<script setup lang="ts">
import { $getEventGroups } from '@/api/settings'
import { uniqBy } from 'lodash'

interface IProps {
  modelValue?: any,
  label?: string,
  activeBrandingCampaignsOnly?: boolean,
  activeSignCampaignsOnly?: boolean,
  activeKopCampaignsOnly?: boolean,
  activeCampaignsOnly?: boolean,
  itemValueErpId?: boolean,
  getAll?: boolean,
  selectOptions?: any[] | null,
  /**
   * Optional array of event group IDs that should be disabled for selection.
   * If null or undefined, no event groups will be disabled.
   */
  disabledEventGroups?: number[] | null,
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: null,
  label: 'Program',
  activeBrandingCampaignsOnly: false,
  activeSignCampaignsOnly: false,
  activeCampaignsOnly: false,
  itemValueErpId: false,
  getAll: false,
  selectOptions: null,
  disabledEventGroups: null,
})

interface CustomEvents {
  'update:modelValue': (newValue: any) => void;
}

const emit = defineEmits<CustomEvents>()

interface EventGroup {
  id: number
  name: string
}

const eventGroups = ref<EventGroup[]>([])
const eventGroupsCurrentPage = ref<number>(1)
const eventGroupsPerPage: number = 10
const eventGroupsSearchStr = ref<string>('')
const isNextEventGroupsPageExist = ref<boolean>(false)
const eventGroupsLoading = ref<boolean>(false)

const eventGroupOptions = computed(() => {
  if (props.selectOptions) {
    return props.selectOptions
  } else {
    return eventGroups.value
  }
})

const getEventGroups = async () => {
  eventGroupsLoading.value = true
  try {
    const res = await $getEventGroups({
      search: eventGroupsSearchStr.value,
      per_page: eventGroupsPerPage,
      page: eventGroupsCurrentPage.value,
      active_branding_campaigns_only: props.activeBrandingCampaignsOnly ? true : undefined,
      active_sign_campaigns_only: props.activeSignCampaignsOnly ? true : undefined,
      active_kop_campaigns_only: props.activeKopCampaignsOnly ? true : undefined,
      active_campaigns_only: props.activeCampaignsOnly ? true : undefined,
      get_all: props.getAll ? true : undefined,
    })

    // Use Lodash's uniqBy to merge arrays while ensuring uniqueness based on 'id'
    eventGroups.value = uniqBy([...eventGroups.value, ...res.data.results], 'id')

    isNextEventGroupsPageExist.value = !!res.data.next
  } catch (e) {
    console.log(e)
  } finally {
    eventGroupsLoading.value = false
  }
}

const onFocusEventGroupSelect = async () => {
  if (!eventGroups.value.length && !props.selectOptions) {
    eventGroups.value = []
    eventGroupsCurrentPage.value = 1
    await getEventGroups()
  }
}

const onLoadMoreEventGroups = async () => {
  eventGroupsCurrentPage.value++ // Increment the current page to load the next page of eventGroups
  await getEventGroups()
}

const clearAndGetEventGroups = (searchStr: string) => {
  eventGroups.value = []
  eventGroupsCurrentPage.value = 1 // Set current page => 1 to load initial first page of eventGroups
  eventGroupsSearchStr.value = searchStr
  getEventGroups()
}

const handleEventGroupSearch = useDebounceFn((searchStr: string) => {
  if (!props.selectOptions) {
    clearAndGetEventGroups(searchStr)
  }
}, 500)

defineExpose({ clearAndGetEventGroups })
</script>

<template>
  <VAutocomplete
    :model-value="modelValue"
    :items="[...eventGroupOptions]"
    return-object
    item-title="name"
    item-value="id"
    :label="label"
    density="compact"
    :no-filter="!selectOptions"
    multiple
    clearable
    @update:modelValue="newValue => $emit('update:modelValue', newValue)"
    @update:focused="onFocusEventGroupSelect"
    @update:search="handleEventGroupSearch"
  >
    <template
      v-if="disabledEventGroups"
      #item="{ item, index, props }"
    >
      <VListItem
        v-bind="props"
        :disabled="!!disabledEventGroups ? disabledEventGroups.includes(item?.value) : false"
      />
    </template>
    <template #selection="{ item, index }">
      <div class="d-flex align-center">
        <v-chip v-if="index < 1">
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="text-grey text-caption align-self-center"
        >
          (+{{ modelValue?.length - 1 }} others)
        </span>
      </div>
    </template>
    <template #no-data>
      <div
        v-if="!!eventGroupsLoading"
        class="d-flex justify-center align-center"
      >
        Loading...
        <VProgressCircular
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
      >
        Programs not found
      </div>
    </template>
    <template
      v-if="isNextEventGroupsPageExist"
      #append-item
    >
      <VBtn
        color="default"
        block
        :disabled="!!eventGroupsLoading"
        @click="onLoadMoreEventGroups"
      >
        Load more...
        <VProgressCircular
          v-if="!!eventGroupsLoading"
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </VBtn>
    </template>
  </VAutocomplete>
</template>

<style lang="scss">

</style>
