<script setup>
import router from '@/router'
import { $getKopItemsChart, $kopItemsGlobalOptions } from '@/api/kop'

// components
const campaignsSelectFilter = ref()

const selectedProjects = ref([])
const projectOptions = ref([])
const selectedLocations = ref([])
const locationOptions = ref([])
const selectedPrograms = ref([])
const programOptions = ref([])

const optionsLoading = ref(false)

const kopItemsChartData = ref([])
const series = ref([])
const total = ref(0)

const chartOptions = ref({
  stroke: { width: 0 },
  labels: [],
  colors: [],
  dataLabels: {
    enabled: true,
    formatter: val => `${Number.parseInt(val, 10)}%`,
  },
  grid: {
    padding: {
      top: 0,
      right: 0,
      bottom: 72,
      left: 0
    }
  },
  legend: {
    floating: true,
    showForSingleSeries: true,
    position: 'bottom',
    markers: { offsetX: -1 },
    fontSize: '13px',
    labels: { colors: '#000' },
    itemMargin: {
      vertical: 2,
      horizontal: 7,
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        minAngleToShowLabel: 0,
        offset: 0,
      },
      donut: {
        labels: {
          show: true,
          name: {
            fontSize: '1.125rem',
            color: '#000',
          },
          value: {
            fontSize: '1.125rem',
            color: '#000',
            formatter: val => `${Number.parseInt(val, 10)}`,
          },
          total: {
            show: true,
            fontSize: '1.125rem',
            label: 'Total KOP Items',
            formatter: () => total.value,
            color: '#000',
          },
        },
      },
    },
  },
  responsive: [
    {
      breakpoint: 992,
      options: {
        chart: {
          height: 380,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
    {
      breakpoint: 576,
      options: {
        chart: {
          height: 320,
        },
        plotOptions: {
          pie: {
            dataLabels: {
              minAngleToShowLabel: 0,
            },
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '0.9375rem',
                },
                value: {
                  fontSize: '0.9375rem',
                },
                total: {
                  fontSize: '0.9375rem',
                },
              },
            },
          },
        },
      },
    },
  ],
})

const getChart = async () => {
  total.value = 0
  series.value = []
  chartOptions.value.labels = []
  chartOptions.value.colors = []
  try {
    const res = await $getKopItemsChart({
      projects: selectedProjects.value.length ? selectedProjects.value.map(i => i.id) : undefined,
      event_groups_or_children: selectedPrograms.value.length ? selectedPrograms.value.map(i => i.id) : undefined,
      locations_or_children: selectedLocations.value.length ? selectedLocations.value.map(i => i.id) : undefined,
    })
    res.data.map(item => {
      if (item.item_status__name) {
        series.value.push(item.status_no)
        total.value += item.status_no
        chartOptions.value.labels.push(item.item_status__name)
        chartOptions.value.colors.push(item.item_status__colour)
      }
    })
    kopItemsChartData.value = res.data
  } catch (e) {
    console.log(e)
  }
}

const getSelectOptions = async () => {
  optionsLoading.value = true
  const payload = {
    filters: JSON.stringify({
      campaign__in: selectedProjects?.value?.length ? selectedProjects.value.map(i => i.id) : undefined,
      campaign__event_group_or_children__in: selectedPrograms?.value?.length ? selectedPrograms.value.map(i => i.id) : undefined,
      location_or_children__in: selectedLocations.value.length ? selectedLocations.value.map(i => i.id) : undefined,
    }),
    program_project_location_options_only: true
  }
  try {
    const res = await $kopItemsGlobalOptions(payload)
    projectOptions.value = res.data.campaign_options
    programOptions.value = res.data.event_group_options
    locationOptions.value = res.data.location_options
  } catch (e) {
    console.log(e)
  } finally {
    optionsLoading.value = false
  }
}

const onPieChartDataPointSelection = async (e, chartContext, config) => {
  const itemStatusId = kopItemsChartData.value[config.dataPointIndex]?.item_status_id
  openGlobalSearchKopItemsPage(itemStatusId)
}

const openGlobalSearchKopItemsPage = (itemStatusId = null) => {
  const query = {
    programs: selectedPrograms.value?.length ? JSON.stringify(selectedPrograms.value) : [],
    projects: selectedProjects.value?.length ? JSON.stringify(selectedProjects.value) : [],
    locations: selectedLocations.value?.length ? JSON.stringify(selectedLocations.value) : []
  }

  if (itemStatusId) {
    query.item_status = itemStatusId
  }

  // Filter out empty or null or undefined values or empty arrays from the query
  const filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => {
      if (Array.isArray(value)) {
        // Filter out empty arrays
        return value.length > 0
      } else {
        // Filter out empty, null, and undefined values
        return value !== '' && value !== null && value !== undefined
      }
    })
  )

  const path = '/projects/kop/global-search-kop-items/'

  // New Window link
  const resolvedPath = router.resolve({
    path: path,
    query: filteredQuery
  })
  window.open(resolvedPath.href, '_blank')
}

onMounted(() => getChart())
</script>

<template>
  <VCard>
    <VCardTitle>
      <VRow class="pa-4">
        <VCol
          cols="12"
          class="d-flex flex-wrap"
        >
          <div class="d-flex align-center mr-1">
            KOP Items
          </div>
        </VCol>
        <VCol cols="12" md="4">
          <Suspense>
            <MultipleEventGroupSelect
              v-model="selectedPrograms"
              :select-options="programOptions"
              :loading="optionsLoading"
              @update:focused="programOptions.length ? '' : getSelectOptions()"
              @update:model-value="getChart(); getSelectOptions()"
            />
          </Suspense>
        </VCol>
        <VCol cols="12" md="4">
          <Suspense>
            <MultipleCampaignsSelect
              v-model="selectedProjects"
              :select-options="projectOptions"
              :type="1"
              :loading="optionsLoading"
              @update:focused="projectOptions.length ? '' : getSelectOptions()"
              @update:modelValue="getChart(); getSelectOptions()"
            />
          </Suspense>
        </VCol>
        <VCol cols="12" md="4">
          <Suspense>
            <MultipleLocationsSelect
              v-model="selectedLocations"
              :select-options="locationOptions"
              :loading="optionsLoading"
              @update:focused="locationOptions.length ? '' : getSelectOptions()"
              @update:modelValue="getChart(); getSelectOptions()"
            />
          </Suspense>
        </VCol>
      </VRow>
    </VCardTitle>
    <VCardText>
      <VRow>
        <VCol
          cols="12"
          class="mt-auto"
        >
          <apexchart
            v-if="series.length"
            type="donut"
            :options="chartOptions"
            :series="series"
            height="400"
            @dataPointSelection="onPieChartDataPointSelection"
          />
          <template v-else>
            <div class="pa-4 d-flex align-center justify-center">
              <h3>No KOP Items in selected project/location</h3>
            </div>
          </template>
        </VCol>
      </VRow>
    </VCardText>
    <VCardText>
      <VBtn
        @click="openGlobalSearchKopItemsPage(null)"
      >
        View All KOP Items
      </VBtn>
    </VCardText>
  </VCard>
</template>

<style lang="scss" scoped>
.card-list {
  --v-card-list-gap: 18px;
}

.wayfinding-chart-type-btn-toggle {
  height: auto !important;
  .text-title {
    font-size: 14px;
    line-height: 16px;
  }
  .text-desc {
    font-size: 10px;
    line-height: 12px;
  }
}
</style>
