import { $getMe } from '@/api/auth'
import ability, { initialAbility } from '@/plugins/casl/ability'
import { useUserStore } from '@/store/user'
import { useDrawingStore } from '@/store/drawing'

export const getCurrentUserData = async () => {
  const userStore = useUserStore()
  const drawingStore = useDrawingStore()

  const loggedIn = userStore.isLoggedIn

  if (loggedIn && !drawingStore.offlineMode) {
    const user = await $getMe()

    userStore.saveUser(user.data)

    // Temporary version - remove(eventRoleAbilities) after new User permissions integration
    const eventRoleAbilities = user.data.event_role_abilities.filter((i: any) => !(i.subject === 'settings' && i.action === 'view'))

    const userAbilities = user.data.user_abilities

    // add CASL abilities for Access control, which includes initialAbility(auth, home page) and event role abilities
    const abilities = [...initialAbility, ...eventRoleAbilities, ...userAbilities]

    ability.update(abilities)
    localStorage.setItem('userAbilities', JSON.stringify(abilities))
  }
}
