<script setup lang="ts">
import { $getUserSigns } from '@/api/signs'
import { useDisplay } from 'vuetify'

interface IProps {
  maxItemsCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
  maxItemsCount: 0,
})

const vuetifyDisplay = useDisplay()

const items = ref([])
const itemsLoading = ref(false)
const itemsCount = ref(0)
const currentPage = ref(1)
const nextPage = ref(null)
const previousPage = ref(null)

const perPage = computed<number>(() => {
  return vuetifyDisplay?.lgAndUp ? 20 : 10
})

const tableHeight = computed<number | string>(() => {
  if (vuetifyDisplay?.lgAndUp) {
    if (props.maxItemsCount === 0) {
      return 100
    }
    return 500
  }
  return ''
})

const getItems = async () => {
  itemsLoading.value = true
  try {
    const res = await $getUserSigns({
      page: currentPage.value,
      per_page: perPage.value
    })

    items.value = res.data.results
    itemsCount.value = res.data.count
    nextPage.value = res.data.next
    previousPage.value = res.data.previous
  } catch (e) {
    console.log(e)
  } finally {
    itemsLoading.value = false
  }
}

onMounted(() => getItems())

// properties & methods are public
defineExpose({
  itemsCount,
})
</script>

<template>
  <VCard title="Wayfinding Signs Assigned To You">
    <VCardText>
      <VOverlay
        v-model="itemsLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VTable
        :fixed-header="$vuetify.display.lgAndUp"
        :height="tableHeight"
      >
        <thead>
          <tr>
            <th>Sign Code</th>
            <th>Status</th>
            <th>Project</th>
            <th>Location</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template
            v-for="item in items"
            :key="item.id"
          >
            <tr>
              <td>{{ item.sign_code }}</td>
              <td>{{ item.sign_status_name }}</td>
              <td>{{ item.campaign_name }}</td>
              <td>{{ item.location_name }}</td>
              <td>
                <SignDetailsModal :sign-id="item.id" @sign-updated="getItems" />
              </td>
            </tr>
          </template>
          <tr v-if="!items.length && !itemsLoading">
            <td colspan="5" class="text-center">Signs not found</td>
          </tr>
        </tbody>
      </VTable>
      <div
        class="mt-2"
      >
        <VBtn
          size="small"
          class="mr-2"
          :disabled="!previousPage"
          @click="currentPage--; getItems()"
        >
          &lt;
        </VBtn>
        <VBtn
          size="small"
          :disabled="!nextPage"
          @click="currentPage++; getItems()"
        >
          >
        </VBtn>
      </div>
    </VCardText>
  </VCard>
</template>
