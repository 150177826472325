import axiosIns from '@axios'

const $http = axiosIns

// Change Requests
export const $getChangeRequests = async (filters: object) => {
  return $http.get('change-requests/', { params: filters })
}

export const $getChangeRequest = async (id: number | string) => {
  return $http.get(`change-requests/${id}/`)
}

export const $getChangeRequestOptions = async () => {
  return $http.options('change-requests/')
}

export const $updateChangeRequest = async (id: number | string, payload: object) => {
  return $http.patch(`change-requests/${id}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $createChangeRequest = async (payload: object) => {
  return await $http.post('change-requests/', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

// Change Request Approvals
export const $getChangeRequestApprovals = async (filters: object) => {
  return $http.get('change-request-approvals/', { params: filters })
}

export const $getChangeRequestApproval = async (id: number | string) => {
  return $http.get(`change-request-approvals/${id}/`)
}

export const $getChangeRequestApprovalOptions = async () => {
  return $http.options('change-request-approvals/')
}

export const $updateChangeRequestApproval = async (id: number | string, payload: object) => {
  return $http.patch(`change-request-approvals/${id}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $createChangeRequestApproval = async (payload: object) => {
  return await $http.post('change-request-approvals/', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $deleteChangeRequestApproval = async (id: number | string) => {
  return await $http.delete(`change-request-approvals/${id}/`)
}

// Change Request Subtasks
export const $getChangeRequestSubtasks = async (filters: object) => {
  return $http.get('change-request-subtasks/', { params: filters })
}

export const $getChangeRequestSubtask = async (id: number | string) => {
  return $http.get(`change-request-subtasks/${id}/`)
}

export const $getChangeRequestSubtaskOptions = async () => {
  return $http.options('change-request-subtasks/')
}

export const $updateChangeRequestSubtask = async (id: number | string, payload: object) => {
  return $http.patch(`change-request-subtasks/${id}/`, payload)
}

export const $createChangeRequestSubtask = async (payload: object) => {
  return await $http.post('change-request-subtasks/', payload)
}

export const $deleteChangeRequestSubtask = async (id: number | string) => {
  return await $http.delete(`change-request-subtasks/${id}/`)
}

// Change Request Global Options
export const $getChangeRequestGlobalOptions = async (filters: object) => {
  return await $http.get('change-request-global-options/', { params: filters })
}
