import {setupLayouts} from 'virtual:generated-layouts'
import {createRouter, createWebHistory} from 'vue-router'
import routes from '~pages'
import { useUserStore } from '@/store/user'
import { getCurrentUserData } from '@/helpers/user/getCurrentUserData'
import ability from '@/plugins/casl/ability'
import type { RouteLocationNormalized } from 'vue-router'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...setupLayouts(routes),
    ],
})

// customCanNavigate - canNavigate with ability to check more than one action and one subject from meta
export const customCanNavigate = (to: RouteLocationNormalized) => {
    // We should allow passing string | undefined to can because for admin ability we omit defining action & subject
    return to.matched.some((route) => {
        const { action, subject } = route.meta

        if (typeof action === 'string' && typeof subject === 'string') {
            // Check if the user has permission for the specified action on the specified subject
            // @ts-ignore
            return ability.can(action, subject)
        } else if (Array.isArray(action) && action.length > 0 && Array.isArray(subject) && subject.length > 0) {
            // Check if the user has permission for at least one action on at least one subject
            // @ts-ignore
            return action.some((act) => subject.some((sub) => ability.can(act, sub)))
        }

        return false
    })
}

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    const loggedIn = userStore.isLoggedIn

    // List of paths and patterns accessible to unauthenticated users
    const publicPaths: string[] = ['/login', '/password-reset']
    const publicPathPatterns: RegExp[] = [/^\/password-reset\/[^/]+\/[^/]+$/]

    // Function to check if the path is public(accessible to unauthenticated users)
    const isPublicPath = (path: string): boolean => {
        if (publicPaths.includes(path)) {
            return true
        }
        return publicPathPatterns.some(pattern => pattern.test(path))
    }

    // If the user is not logged in and trying to access a protected route, redirect to login
    if (!loggedIn && !isPublicPath(to.path)) {
        next({ name: 'login' })
    }
    // If the user is logged in
    else if (loggedIn) {
        await getCurrentUserData()

        // If the user has permission to navigate
        if (customCanNavigate(to)) {
            if (to.meta.redirectIfLoggedIn) {
                next('/')
            }
            next()
        // if user don't have permission -> redirect to home page
        } else {
            next('/')
        }
    }
    // If the user is not logged in but accessing a public path
    else {
        next()
    }
})

export default router
