import axiosIns from '@axios'

const $http = axiosIns

// KOP Item Types
export const $getKopItemTypes = async (filters: object) => {
  return await $http.get('kop-item-types/', { params: filters })
}

export const $createKopItemType = async (payload: object) => {
  return await $http.post('kop-item-types/', payload)
}

export const $updateKopItemType = async (id: number | string, payload: object) => {
  return await $http.patch(`kop-item-types/${id}/`, payload)
}

export const $deleteKopItemType = async (id: number) => {
  return await $http.delete(`kop-item-types/${id}/`)
}

export const $getKopItemType = async (id: number) => {
  return await $http.get(`kop-item-types/${id}/`)
}

export const $getKopItemTypeOptions = async () => {
  return await $http.options('kop-item-types/')
}

// KOP Items
export const $getKopItems = async (filters: object) => {
  return await $http.get('kop-items/', { params: filters })
}

export const $createKopItem = async (payload: object) => {
  return await $http.post('kop-items/', payload)
}

export const $updateKopItem = async (id: number | string, payload: object) => {
  return await $http.patch(`kop-items/${id}/`, payload)
}

export const $deleteKopItem = async (id: number) => {
  return await $http.delete(`kop-items/${id}/`)
}

export const $getKopItem = async (id: number) => {
  return await $http.get(`kop-items/${id}/`)
}

export const $getKopItemOptions = async () => {
  return await $http.options('kop-items/')
}

// KOP Scoping Photos
export const $getKopScopingPhotos = async (params: object) => {
  return await $http.get('/kop-scoping-photos/', { params: params })
}

export const $createKopScopingPhoto = async (payload: object) => {
  return await $http.post('/kop-scoping-photos/', payload)
}

export const $deleteKopScopingPhoto = async (id: number | string) => {
  return await $http.delete(`/kop-scoping-photos/${id}/`)
}

// KOP Item Chart
export const $getKopItemsChart = async (filters: object) => {
  return await $http.get('kop-items-chart/', { params: filters })
}

// User KOP Items
export const $getUserKopItems = async (filters: object) => {
  return await $http.get('kop-items-user/', { params: filters })
}

// Global KOP Item Search
export const $kopItemsGlobalOptions = async (filters: object) => {
  return await $http.get('kop-items-global-options/', { params: filters })
}

// Multiple KOP Items update
export const $multipleUpdateKopItemFA = async (payload: object) => {
  return await $http.post('multiple-update-kop-item-fa/', payload)
}

export const $multipleUpdateKopItemAssignee = async (payload: object) => {
  return await $http.post('multiple-update-kop-item-assignee/', payload)
}

export const $multipleUpdateKopItemStatus = async (payload: object) => {
  return await $http.post('multiple-update-kop-item-status/', payload)
}

export const $multipleUpdateKopItemOnHold = async (payload: object) => {
  return await $http.post('multiple-update-kop-item-on-hold/', payload)
}

export const $multipleUpdateKopItemPriority = async (payload: object) => {
  return await $http.post('multiple-update-kop-item-priority/', payload)
}

// Approve/reject Kop Item
export const $approveKopItem = async (id: number | string, payload: object) => {
  return await $http.patch(`approve-kop-item/${id}`, payload)
}

export const $multipleApproveKopItem = async (payload: object) => {
  return await $http.post('multiple-approve-kop-item/', payload)
}

