import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import Konva from 'konva'
import { isEqual, cloneDeep } from 'lodash'

// import Snackbar
import { useSnackbarStore } from '@/store/snackbar'

// import API
import {
    $createDrawing,
    $getCampaignLastDrawings,
    $getDrawing,
    $updateDrawing,
    $drawingMapDeleteItems, $drawingMapSaveItems,
} from '@/api/campaigns'
import { $createOfflineItems, $createSign, $updateSign } from '@/api/signs'
import { $createKopItem, $updateKopItem } from '@/api/kop'

// import helpers
import { addDrawingItem, updateDrawingItem } from '@/store/helpers/drawItemsHelpers'
import {
    saveDefaultSignTypesToLocalStorage,
    loadDefaultSignTypesFromLocalStorage,
    saveDefaultLocationsToLocalStorage,
    loadDefaultLocationsFromLocalStorage,
    getUpdatedLocationImageUrlRecursively,
    getSignTypesWithImg,
} from '@/store/helpers/offlineItems/main'
import { idbClearAllImages } from '@/store/helpers/offlineItems/indexedDB'
import { transformDrawingsArrayToObj } from '@/store/helpers/offlineItems/utils'
import { updateItemsLonLat } from '@/store/helpers/update-items-lon-lat'
import { getLocationById } from '@/helpers/wayfinding/locations/getLocationById'
import { loadImage } from '@/store/helpers/utils/loadItemImage'

// Types
import type { SignPayload } from '@/store/models/sign'
import type { KopItemPayload } from '@/models/kop/drawingStore'

const signData = (id: string, x: number, y: number, sign: any, height: number) => {
    // const image = new window.Image()
    // image.src = sign.source
    const tagX = x + 10
    const tagY = y + 12
    return {
        type: 'sign',
        id: id,
        x: x,
        y: y,
        tagX: tagX,
        tagY: tagY,
        height,
        width: 25,
        opacity: 1,
        draggable: true,
        file: sign.source,
        sign: null
    }
}

const kopItemData = (id: string, x: number, y: number, kopItemType: any) => {
    const tagX = x + (kopItemType.shape_width / 2 + 5)
    const tagY = y + (kopItemType.shape_height / 2 + 5)
    return {
        type: 'kop_item',
        id: id,
        x: x,
        y: y,
        tagX: tagX,
        tagY: tagY,
        width: +kopItemType.shape_width,  // to number
        height: +kopItemType.shape_height,  // to number
        opacity: 1,
        draggable: true,
        shape_items: kopItemType.shape,
        kop_item: null
    }
}

const restoredKopItemData = (id: string, kopItem: any) => {
    const tagX = parseFloat(kopItem.left) + (kopItem.kop_item_type_info.shape_width / 2 + 5)
    const tagY = parseFloat(kopItem.top) + (kopItem.kop_item_type_info.shape_height / 2 + 5)
    return {
        type: 'kop_item',
        id: id,
        x: parseFloat(kopItem.left),
        y: parseFloat(kopItem.top),
        rotation: kopItem.direction,
        tagX: tagX,
        tagY: tagY,
        width: +kopItem.kop_item_type_info.shape_width,  // to number
        height: +kopItem.kop_item_type_info.shape_height,  // to number
        opacity: 1,
        draggable: true,
        shape_items: kopItem.kop_item_type_info.shape,
        kop_item: kopItem.id
    }
}

const labelData = (id: string, x: number, y: number, parent: any) => {
    return {id: id, type: 'label', x: x, y: y, parent: parent, width: 100, height: 40,}
}

const restoredSignData = async (id: string, sign: any) => {
    const { width, height } = await loadImage(sign?.type_info?.source)
    const ratio = width / height

    // const image = new window.Image()
    // image.src = sign.source
    const tagX = parseFloat(sign.left) + 10
    const tagY = parseFloat(sign.top) + 12
    return {
        type: 'sign',
        id: id,
        x: parseFloat(sign.left),
        y: parseFloat(sign.top),
        tagX: tagX,
        tagY: tagY,
        height: 25 / ratio,
        width: 25,
        opacity: 1,
        draggable: true,
        file: sign.type_info ? sign.type_info.source : '',
        sign: sign.id,
    }
}

export const useDrawingStore = defineStore({
    id: 'drawing',
    persist: {
        paths: ['items', 'offlineSigns', 'geoPoints']
    },
    state: () => ({
        stage: null as any,
        pdfStageLayerKey: 10000 as number,
        isEditKopItemTypeShape: false as boolean,
        darkMode: false,
        offlineMode: false,
        offlineModeObjectUrls: [] as string[],  // List to store object URLs
        handleOfflineModeLoading: false as boolean,  // List to store object URLs
        allDefaultDrawingItems: {} as any,  // All drawing items by all locations { "locationId": "items[]" }
        showLabels: false,
        project: {} as any,
        currentDrawingId: null as null | number,
        initialItems: [] as any[],  // last DB actual items
        items: [] as any[],
        offlineItems: [] as any[],  //  offline Items - all(except Signs/Sign Labels), lines, text, etc.
        offlineSigns: [] as any[],  // offline Signs
        offlineSignLabels: [] as any[],  // offline Sign Labels
        uploadOfflineSignsLoading: false as boolean,
        selectedBtn: 'lock',
        selectedItem: null as any,
        selectedItems: [] as any,
        drawingItem: null as any,
        selectedSignType: null as any,
        selectedSignIds: [] as any,
        signDialogVisible: false as boolean,
        kopItemDialogVisible: false as boolean,
        hoveredItem: null as any,
        signTypes: [] as any,
        signStatuses: [] as any,
        funcAreas: [] as any,
        locations: {} as any,
        selectedLocation: null as any,
        mapLoading: false,
        drawingLoading: false,
        locationSetupLoading: false,
        loadDrawingMapImgTrigger: 0, // loadMapImg on trigger > 0
        transformer: null as any,
        fullscreen: false,
        isDrawing: false,
        selectedArea: null as any,
        sessionKey: Math.random(),
        mousePos: {
            x: 0,
            y: 0,
        },
        bgImg: {image: null, x: 0, y: 0, width: 0, height: 0, filters: [Konva.Filters.Grayscale]}, // Konva bg image
        width: 0, // Width of the rendered Map, including scale. Set to 0 by default.
        height: 0, // Height of the rendered Map, including scale. Set to 0 by default.
        scale: {
            x: 1,
            y: 1,
        },
        initialZoom: null as null | number,
        geoMapKey: 5000 as number,
        isGeoSetMode: false,
        geoMapSetModeOpacity: 0.5 as number,
        isGeoMapMode: false,
        isGeoDrawingMode: false,
        geoPoints: [] as any[],
        pdfCornersGeo: [] as any[],
        mapCenter: [0, 0] as number[],
        geoMapBearing: 0 as number,
        selectedFARouteId: null as null | number | string,
        isSetRouteMode: false,
        funcAreaRoutes: [] as any[],
        // KOP Project
        kopItemTypes: [] as any[],
        selectedKopItemType: null as null | any,

        // HISTORY (UNDO/REDO)
        drawingHistory: [] as { id: number, x: number, y: number }[][], // Only track id, x, y
        drawingHistoryStep: 0 // Track the current position in undo/redo history, start from 0
    }),
    getters: {
        isEditMode: state => ['lock', 'move'].includes(state.selectedBtn),
        isMoveMode: state => state.selectedBtn === 'move',
        zoom: state => Math.round(state.scale.x * 100),
        locationMap: state => {
            if (state.selectedLocation) {
                return state.selectedLocation.modified_image
            } else if (state.project) {
                return state.project.location_modified_image
            } else {
                return null
            }
        },
        isGeoMapVisible: state => state.isGeoSetMode || state.isGeoMapMode || state.isGeoDrawingMode,
        selectedFARouteIdx: state => state.funcAreaRoutes.findIndex((i: any) => i.id === state.selectedFARouteId),
        // Getter to get the changed item ids
        changedItemIds(state) {
            return state.initialItems
              .map(initialItem => {
                  // Find the item with the same id in the current state
                  const currentItem = state.items.find(item => item.id === initialItem.id)

                  // Use Lodash's cloneDeep to create deep copies and remove 'draggable' field
                  const cleanInitialItem = cloneDeep(initialItem)
                  const cleanCurrentItem = cloneDeep(currentItem)

                  // Remove the 'draggable' field from both items
                  delete cleanInitialItem.draggable
                  delete cleanCurrentItem.draggable

                  // Compare the cleaned items using Lodash's isEqual
                  return currentItem && !isEqual(cleanCurrentItem, cleanInitialItem) ? initialItem.id : null
              })
              .filter(id => !!id) // Filter out falsy values
        },

        // Getter to get newly created item ids
        newlyCreatedItemIds(state) {
            return state.items
              .filter(item => {
                  // Check if item does not exist in initialItems and if the item's type is not sign/kop_item/label
                  const existsInInitial = state.initialItems.some(initialItem => initialItem.id === item.id)
                  return !existsInInitial && !['sign', 'kop_item', 'label'].includes(item.type) // Check for new items
              })
              .map(item => item.id) // Return only the ids of new items
        },
    },
    actions: {
        updatePdfStageKey() {
            this.pdfStageLayerKey = this.pdfStageLayerKey + 1
        },
        updateIsEditKopItemTypeShape(isEditKopItemTypeShape: boolean) {
            this.isEditKopItemTypeShape = isEditKopItemTypeShape
        },
        updateCurrentDrawingId(currentDrawingId: null | number) {
            this.currentDrawingId = currentDrawingId
        },
        updateInitialItems(initialItems: any[]) {
            this.initialItems = JSON.parse(JSON.stringify(initialItems))
        },
        getNewAndChangedItems() {
            // Filter 'this.items' to return only those items whose 'id' is in 'newlyCreatedItemIds'
            const newlyCreatedItems = this.items.filter(item =>
              // Check if the current item's 'id' exists in 'newlyCreatedItemIds' array
              this.newlyCreatedItemIds.includes(item.id)
            )

            // Filter 'this.items' to return only those items whose 'id' is in 'changedItemIds'
            const changedItems = this.items.filter(item =>
              // Check if the current item's 'id' exists in 'changedItemIds' array
              this.changedItemIds.includes(item.id)
            )

            return { newlyCreatedItems, changedItems }
        },
        // Handle the selection of a new location
        async onSelectLocation() {
            // Retrieve newly created items and items that have changed
            const { newlyCreatedItems, changedItems } = this.getNewAndChangedItems()

            // Check if there are any newly created or changed items
            // If there are, save the items before switching to the new location
            if (newlyCreatedItems.length || changedItems.length) {
                await this.saveItems()  // Save the current items to persist changes
            }

            // Clear Drawing History
            this.clearDrawingHistory()
        },
        updateLocationSetupLoading(locationSetupLoading: boolean) {
            this.locationSetupLoading = locationSetupLoading
        },
        updateLoadDrawingMapImgTrigger(loadDrawingMapImgTrigger: number) {
            this.loadDrawingMapImgTrigger = loadDrawingMapImgTrigger
        },
        clearDrawingHistory() {
            this.drawingHistory = []
            this.drawingHistoryStep = 0
        },
        clearDrawingBgImg() {
            this.bgImg.image = null
        },
        clearDrawingTopBar() {
            this.selectedBtn = 'lock'
            this.selectedSignType = null
        },
        clearItems() {
          this.items = []
        },
        setItems(items: any[]) {
            this.items = items
            this.lockMoving()
        },
        async saveItems() {
            const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

            // Wayfinding
            if (this.project.type === 1) {
                const { newlyCreatedItems: unsavedItems, changedItems } = this.getNewAndChangedItems()

                const payload = {
                    campaign_id: this.project.id,
                    location_id: location,
                    // Changed and not saved items for data consistency
                    updated_drawing_items: JSON.stringify(changedItems),
                    // Unsaved drawing items to save before Sign/Item delete for data consistency
                    unsaved_drawing_items: JSON.stringify(unsavedItems),
                    geo_points: this.geoPoints,
                    fa_routes: this.funcAreaRoutes
                }

                // return Actual Drawing Data
                const res = await $drawingMapSaveItems(payload)

                // Update current Drawing ID
                this.updateCurrentDrawingId(res.data.id)

                // Update initial items
                this.updateInitialItems(res.data.modified_items)

                return res
            }
            // KOP
            else {
                if (this.kopItemTypes.length) {
                    const payload = {
                        project: this.project.id,
                        items: this.items.filter(i => {
                            // Remove FA Routes items
                            return !['route', 'anchor'].includes(i.tag)
                        }).map(obj => {
                            // remove computed server keys
                            const {
                                sign_status,
                                sign_type,
                                sign_type_colour,
                                sign_additional_functional_areas,
                                sign_short_code,
                                sign_short_name,
                                sign_qty,
                                is_released,
                                is_can_user_approve,
                                ...rest
                            } = obj
                            return rest
                        }),
                        location: location,
                        geo_points: this.geoPoints,
                        fa_routes: this.funcAreaRoutes
                    }
                    const res = await $createDrawing(payload)
                    // Update current Drawing ID
                    this.updateCurrentDrawingId(res.data.id)

                    return res
                }
            }
        },

        async getDrawing(isLoading = true, isClearItems = false) {
            if (isLoading) {
                this.drawingLoading = true
            }

            if (isClearItems) {
                this.clearItems()
            }
            try {
                const location = this.selectedLocation ? this.selectedLocation.id : this.project?.location

                const resDrawing = await $getDrawing({
                    project: this.project.id,
                    location: location,
                    last: true // get only the last drawing
                })
                if (resDrawing.data.results.length) {
                    // Update current Drawing ID
                    this.updateCurrentDrawingId(resDrawing.data.results[0].id)

                    // Update initial items
                    this.updateInitialItems(resDrawing.data.results[0].modified_items)

                    // items - all signs, modified_items - signs with sign types and functional areas
                    this.setItems(resDrawing.data.results[0].modified_items)
                    // Update funcAreaRoutes with last drawing info
                    this.setFuncAreaRoutes(resDrawing.data.results[0].fa_routes)
                    // Update geoPoints with last drawing main location info
                    this.addGeoPoints(resDrawing.data.results[0].geo_points)
                } else {
                    // Update current Drawing ID
                    this.updateCurrentDrawingId(null)

                    // Update initial items
                    this.updateInitialItems([])

                    this.setItems([])
                    this.setFuncAreaRoutes([])
                    this.addGeoPoints([])
                }
            } catch (e) {
                console.log(e)
            } finally {
                if (isLoading) {
                    this.drawingLoading = false
                }
            }
        },

        setZoom(scale: number) {
            this.scale.x = Number(scale.toFixed(1))
            this.scale.y = Number(scale.toFixed(1))

            this.height = (this.bgImg.height) * this.scale.x
            this.width = this.bgImg.width * this.scale.x

            // Center map position on setZoom
            const stage = this.stage?.getStage()
            if (!stage) return

            const pdfWrapper = document.getElementById('map-wrapper')
            const pdfWrapperWidth = pdfWrapper?.offsetWidth || 0
            const pdfWrapperHeight = pdfWrapper?.offsetHeight || 0

            const centerX = (this.width - pdfWrapperWidth) / 2
            const centerY = (this.height - pdfWrapperHeight) / 2

            stage.position({ x: -centerX, y: -centerY })
        },
        increaseZoom() {
            this.scale.x += 0.05
            this.scale.y += 0.05

            this.height = (this.bgImg.height) * this.scale.x
            this.width = this.bgImg.width * this.scale.x
        },
        decreaseZoom() {
            if (this.scale.x > 0.1) {
                this.scale.x -= 0.05
                this.scale.y -= 0.05

                this.height = (this.bgImg.height) * this.scale.x
                this.width = this.bgImg.width * this.scale.x
            }
        },
        handleStageZoom(newScale: number) {
            this.scale.x = newScale
            this.scale.y = newScale

            this.height = (this.bgImg.height) * newScale
            this.width = this.bgImg.width * newScale
        },
        findItemById(item: any) {
            return this.items.find(i => i.id === item.attrs.id)
        },
        findOfflineItemById(item: any) {
            return this.offlineSigns.find(i => i.id === item.attrs.id)
        },
        findItemBySign(signParam: any) {
            return this.items.find(i => i.sign === parseInt(signParam))
        },

        async deleteSelectedItem() {
            const selectedItemIds = this.selectedItems.map((item: any) => item.id)
            if (!this.offlineMode) {
                const itemsToDelete = []
                for (const selectedItem of this.selectedItems) {
                    if (selectedItem && selectedItem.type === 'sign' && selectedItem.sign) {
                        itemsToDelete.push({
                            id: selectedItem.id,
                            type: 'sign',
                            sign: selectedItem.sign,
                            top: selectedItem.y,
                            left: selectedItem.x
                        })
                    }
                    else if (selectedItem && selectedItem.type === 'kop_item' && selectedItem.kop_item) {
                        itemsToDelete.push({
                            id: selectedItem.id,
                            type: 'kop_item',
                            kop_item: selectedItem.kop_item,
                            top: selectedItem.y,
                            left: selectedItem.x,
                            shape_items: selectedItem.shape_items,
                            direction: selectedItem?.rotation || 0,
                            // TODO: Update here after Backend update(KopItem model - scale_x scale_y fields)
                            // scale_x: selectedItem?.scaleX || 1,
                            // scale_y: selectedItem?.scaleY || 1,
                        })
                    }
                    else {
                        itemsToDelete.push({ id: selectedItem.id, type: selectedItem.type })
                    }
                }

                const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

                const { newlyCreatedItems: unsavedItems, changedItems } = this.getNewAndChangedItems()

                const payload = {
                    campaign_id: this.project.id,
                    location_id: location,
                    items_to_delete: JSON.stringify(itemsToDelete),
                    // Changed and not saved items for data consistency
                    updated_drawing_items: JSON.stringify(changedItems),
                    // Unsaved drawing items to save before Sign/Item delete for data consistency
                    unsaved_drawing_items: JSON.stringify(unsavedItems)
                }

                // return Actual Drawing with newly added Sign
                const res = await $drawingMapDeleteItems(payload)

                // Update current items with new Drawing Items
                this.setItems(res.data.modified_items)
                // Update current Drawing ID
                this.updateCurrentDrawingId(res.data.id)

                // Update initial items
                this.updateInitialItems(res.data.modified_items)
            } else {
                this.items = this.items.filter(item => !selectedItemIds.includes(item.id) && !(item.type === 'label' && selectedItemIds.includes(item.parent)))
                this.offlineItems = this.offlineItems.filter(item => !selectedItemIds.includes(item.id))
                this.offlineSigns = this.offlineSigns.filter(item => !selectedItemIds.includes(item.id))
                this.offlineSignLabels = this.offlineSignLabels.filter(item => !selectedItemIds.includes(item.parent))
            }

            // Clear selected Items
            this.selectedItem = null
            this.selectedItems = []
        },

        async restoreDeletedSign(signId: string | number, signType: any) {
            const { newlyCreatedItems: unsavedItems, changedItems } = this.getNewAndChangedItems()

            const { width, height } = await loadImage(signType?.source)
            const ratio = width / height

            const payload = {
                is_removed: false,
                // Drawing Sign Data to restore sign + create new drawing with newly restored sign and return
                drawing_sign_data: JSON.stringify({
                    width: 25,
                    height: 25 / ratio,
                }),
                // Changed and not saved items for data consistency
                updated_drawing_items: JSON.stringify(changedItems),
                // Unsaved drawing items to save before Sign delete for data consistency
                unsaved_drawing_items: JSON.stringify(unsavedItems),
            }

            // return Actual Drawing with newly restored Sign
            const res = await $updateSign(signId, payload)

            // Update current items with new Drawing Items
            this.setItems(res.data.modified_items)
            // Update current Drawing ID
            this.updateCurrentDrawingId(res.data.id)

            // Update initial items
            this.updateInitialItems(res.data.modified_items)
        },

        async restoreDeletedKopItem(selectedDeletedKopItemId: string | number) {
            const restoredKopItemRes = await $updateKopItem(selectedDeletedKopItemId, {
                is_removed: false,
            })
            const restoredKopItem = restoredKopItemRes.data
            const id = uuidv4()
            const label_id = uuidv4()
            this.items.push(restoredKopItemData(id, restoredKopItem))
            // this.items.push(labelData(label_id, parseFloat(restoredKopItem.left) + 10, parseFloat(restoredKopItem.top) + 25, id))

            const res = await this.saveItems()
            this.setItems(res.data.modified_items)
        },
        updateItemPosition(item: any) {
            const itemToUpdate = this.findItemById(item)
            if (itemToUpdate) {
                itemToUpdate.x = item.attrs.x
                itemToUpdate.y = item.attrs.y
            }
        },
        updateOfflineItemPosition(item: any) {
            const itemToUpdate = this.findOfflineItemById(item)
            if (itemToUpdate) {
                itemToUpdate.x = item.attrs.x
                itemToUpdate.y = item.attrs.y
            }
        },
        selectBtn(btn: string) {
            this.selectedBtn = btn
        },
        selectDrawingItemById(id: string) {
            this.selectedItem = this.items.find(item => item.id === id)
            this.selectedItems = [this.items.find(item => item.id === id)]
        },
        asyncSelectDrawingItemById(id: string) {
            // Use setTimeout to ensure the DOM is updated before attempting to select the new item
            setTimeout(() => {
                // Call the method to select the new drawing item by its ID
                this.selectDrawingItemById(id)
            }, 0) // Delay of 0ms to push the function to the end of the event loop
        },
        calculateImgCenter(img: any) {
            // Set default values for scaleX and scaleY if not set
            const scaleX = img.scaleX ? img.scaleX : 1
            const scaleY = img.scaleY ? img.scaleY : 1

            const centerX = img.x + (img.width * scaleX) / 2
            const centerY = img.y + (img.height * scaleY) / 2
            const rotationInRadians = ((img?.rotation || 0) * Math.PI) / 180
            const rotatedCenterX =
                img.x + Math.cos(rotationInRadians) * (centerX - img.x) -
                Math.sin(rotationInRadians) * (centerY - img.y)
            const rotatedCenterY =
                img.y + Math.sin(rotationInRadians) * (centerX - img.x) +
                Math.cos(rotationInRadians) * (centerY - img.y)
            return [rotatedCenterX, rotatedCenterY]
        },
        setFuncAreaRoutes(funcAreaRoutes: any) {
            if (funcAreaRoutes && Array.isArray(funcAreaRoutes)) {
                // FA Routes hidden by default
                this.funcAreaRoutes = funcAreaRoutes.map(i => ({ ...i, is_shown: false }))
            } else {
                this.funcAreaRoutes = []
            }
        },
        async uploadCurrentFuncAreaRoutes() {
            try {
                // Update current drawing if exist
                if (this.currentDrawingId) {
                    const payload = {
                        fa_routes: this.funcAreaRoutes
                    }
                    await $updateDrawing(this.currentDrawingId, payload)
                }
                // Else create drawing with new funcAreaRoutes
                else {
                    const res = await this.saveItems()
                    this.setItems(res.data.modified_items)
                }
            } catch (e) {
                console.log(e)
            }
        },
        addNewRouteLine(color?: string) {
            const stroke = this.funcAreaRoutes[this.selectedFARouteIdx]?.color
            return {
                type: 'line',
                id: uuidv4(),
                tag: 'route',
                points: [],
                stroke: stroke || color,
                strokeWidth: 3,
                lineCap: 'round',
                opacity: 1,
                dash: [10, 10],
                draggable: false,
                hitStrokeWidth: 10,
                offset: 10,
                anchor: [],
                faRouteId: this.funcAreaRoutes[this.selectedFARouteIdx].id,
                faId: this.funcAreaRoutes[this.selectedFARouteIdx].fa_id,
            }
        },
        changeRouteSignsOrder (signsOrder: any[]) {
            const newLines: any[] = []
            for(let i = 0; i < signsOrder.length - 1; i++) {
                const line = this.addNewRouteLine()
                //@ts-ignore
                line.points = [ ...this.calculateImgCenter(signsOrder[i]), ...this.calculateImgCenter(signsOrder[i + 1])]
                newLines.push(line)
            }
            this.funcAreaRoutes[this.selectedFARouteIdx] = {
                ...this.funcAreaRoutes[this.selectedFARouteIdx],
                signsOrder,
                lines: newLines,
            }
            this.items = this.items.filter(item => item.tag !== 'route' && item.tag !== 'anchor')
            this.items = [...this.items, ...this.funcAreaRoutes[this.selectedFARouteIdx].lines]
        },
        changeRouteSettings(selectedFARouteIdx: number, selectedFARouteId: string, name: string, color: string) {
            this.funcAreaRoutes[selectedFARouteIdx] = {
                ...this.funcAreaRoutes[selectedFARouteIdx],
                name,
                color,
                lines: this.funcAreaRoutes[selectedFARouteIdx].lines.map((item: any) => ({...item, stroke: color})),
            }
            this.items = this.items.map(item => item.faRouteId === selectedFARouteId && item.tag === 'route' ? {...item, stroke: color} : item)
        },
        createFARoute (funcAreaId: number, routeName: string, color: string) {
            // Hide All FA Routes before Create New Route
            this.hideAllFARoutes()

            const newRouteId = uuidv4()
            this.funcAreaRoutes.push({
                id: newRouteId,
                name: routeName,
                fa_id: funcAreaId,
                lines: [],
                color,
                signsOrder: [],
                is_shown: true
            })
            this.selectedFARouteId = newRouteId

            this.selectedBtn = 'lock'
            this.isSetRouteMode = true
            const line = this.addNewRouteLine(color)
            this.funcAreaRoutes[this.selectedFARouteIdx].lines = [line]

            this.items = [...this.items, ...this.funcAreaRoutes[this.selectedFARouteIdx].lines]
        },
        editFARoute (faRouteId: string) {
            // Hide All FA Routes before Edit Route
            this.hideAllFARoutes()

            this.selectedFARouteId = faRouteId

            this.selectedBtn = 'lock'
            this.isSetRouteMode = true
            this.funcAreaRoutes[this.selectedFARouteIdx].is_shown = true

            this.items = [...this.items, ...this.funcAreaRoutes[this.selectedFARouteIdx].lines]
        },
        addRouteLine (e: any) {
            const funcAreaLines = this.funcAreaRoutes[this.selectedFARouteIdx].lines.filter((item: any) => item.tag !== 'anchor')
            const targetRoute = funcAreaLines[funcAreaLines.length - 1]
            if (e.target?.attrs?.type === 'sign') {
                const isSignInRoute = this.funcAreaRoutes[this.selectedFARouteIdx].signsOrder?.findIndex((item: any) => item.id === e.target?.attrs?.id)
                if (isSignInRoute === -1) {
                    this.funcAreaRoutes[this.selectedFARouteIdx].signsOrder.push(e.target?.attrs)
                }
                if (targetRoute?.points?.length < 3) {
                    targetRoute.points = [ ...(targetRoute.points || []), ...this.calculateImgCenter(e.target.attrs)]
                    this.items.find(item => item.id === targetRoute.id).points = [...targetRoute.points]
                } else {
                    const line = this.addNewRouteLine()
                    //@ts-ignore
                    line.points = [ ...targetRoute.points.slice(-2), ...this.calculateImgCenter(e.target.attrs)]
                    this.funcAreaRoutes[this.selectedFARouteIdx].lines.push(line)
                    this.items.push(line)
                }
            } else if (e.target?.attrs?.tag === 'route') {
                const pos = e.target.getStage().getPointerPosition()
                const x = (pos.x - e.currentTarget.attrs.x) / this.scale.x
                const y = (pos.y - e.currentTarget.attrs.y) / this.scale.y
                const id = uuidv4()
                const anchor = {
                    x,
                    y,
                    radius: 6,
                    stroke: 'black',
                    fill: 'white',
                    strokeWidth: 2,
                    draggable: true,
                    type: 'circle',
                    tag: 'anchor',
                    opacity: 1,
                    id,
                };
                this.items.push(anchor)
                this.addAnchorToLine(e, x, y, id)
            }
        },
        addAnchorToLine (e: any, xAnchor: number, yAnchor: number, id: string) {
            const line = e.target.attrs
            let anchorIndex = 0;
            const isVertical = Math.abs(line.points[0] - line.points[line.points.length - 2]) < Math.abs(line.points[1] - line.points[line.points.length - 1])
            const isLeftToRight = line.points[0] - line.points[line.points.length - 2] < 0
            const isUpToDown = line.points[1] - line.points[line.points.length - 1] < 0
            let minDiff = isVertical ? Math.abs(yAnchor - line.points[1]) : Math.abs(xAnchor - line.points[0]);

            for (let i = isVertical ? 3 : 2; i <= line.points.length - 2; i += 2) {
                const diff = isVertical ? Math.abs(yAnchor - line.points[i]) : Math.abs(xAnchor - line.points[i]);
                if (diff < minDiff) {
                    minDiff = diff;
                    if (isVertical) {
                        anchorIndex = isUpToDown ? i + 1 : i - 1;
                    } else {
                        anchorIndex = isLeftToRight ? i + 2 : i;
                    }
                }
            }
            anchorIndex = Math.max(2, Math.min(anchorIndex, line.points.length - 2));

            if (anchorIndex) {
                line.anchor.push({ id, x: xAnchor, y: yAnchor})
                line.points.splice(anchorIndex, 0, xAnchor, yAnchor);
                this.items.find(item => item.id === line.id).points = [...line.points];
            }
        },
        updateLineAnchor (e: any) {
            const anchorNew = e.target.attrs
            const line = this.items.find(item => item.anchor &&
                item.anchor.some((anchorItem: any) => anchorItem.id === anchorNew.id));
            const anchor = line.anchor.find((item: any) => item.id === anchorNew.id)
            const index = line.points.findIndex((item: any) => item === anchor.x)
            line.points.splice(index, 2, anchorNew.x, anchorNew.y)
            anchor.x = anchorNew.x
            anchor.y = anchorNew.y
            this.items.find(item => item.id === line.id).points = [...line.points]
        },
        hideAllFARoutes () {
            this.isSetRouteMode = false
            this.selectedFARouteId = null
            this.funcAreaRoutes = this.funcAreaRoutes.map(i => ({ ...i, is_shown: false }))
            this.items = this.items.filter(item => item.tag !== 'route' && item.tag !== 'anchor')
        },
        async saveRoute () {
           const lineRoutes = this.items.filter(item => item.tag === 'route' || item.tag === 'anchor')
           this.funcAreaRoutes[this.selectedFARouteIdx as number].lines = [...lineRoutes]
           await this.uploadCurrentFuncAreaRoutes()
        },
        async deleteRoute () {
            this.funcAreaRoutes.splice(this.selectedFARouteIdx, 1)
            this.hideAllFARoutes()
            await this.uploadCurrentFuncAreaRoutes()
        },
        showSignRoute (faRouteId: string) {
            const faRouteIdx = this.funcAreaRoutes.findIndex((i) => i.id === faRouteId)
            if (this.funcAreaRoutes[faRouteIdx]) {
                this.funcAreaRoutes[faRouteIdx].is_shown = true
                this.items = [
                  ...this.items,
                  // show FA Routes without anchors
                  // i.e. without ability to change routes because not is SetRouteMode, only View
                  ...this.funcAreaRoutes[faRouteIdx].lines.filter((i: any) => !(i.tag === 'anchor'))
                ]
            }
        },
        hideSignRoute (faRouteId: string) {
            const faRouteIdx = this.funcAreaRoutes.findIndex((i) => i.id === faRouteId)

            if (this.funcAreaRoutes[faRouteIdx]) {
                this.funcAreaRoutes[faRouteIdx].is_shown = false
                const funcIdItems = this.funcAreaRoutes[faRouteIdx].lines.map((item: any) => item.id)
                this.items = this.items.filter(item => !funcIdItems.includes(item.id))
            }
        },
        showAllSignRoutes() {
            this.funcAreaRoutes = this.funcAreaRoutes.map(i => ({ ...i, is_shown: true }))
            this.items = [
              ...this.items,
                // show All FA Routes without anchors
                // i.e. without ability to change routes because not is SetRouteMode, only View
              ...this.funcAreaRoutes.flatMap(route => route.lines.filter((i: any) => !(i.tag === 'anchor')) || [])
            ]
        },
        selectSignType(signType: object) {
            this.selectedSignType = signType
            this.selectedBtn = 'sign'
        },
        selectKopItemType(kopItemType: object) {
            this.selectedKopItemType = kopItemType
            this.selectedBtn = 'kop'
        },
        updateGeoMapKey() {
            this.geoMapKey = this.geoMapKey + 1
        },
        addGeoPoints(geoData: any[]) {
            if (geoData) {
                this.geoPoints = [...geoData]
            } else {
                this.geoPoints = []
            }
        },
        async uploadCurrentGeoPoints() {
            try {
                // Update current drawing if exist
                if (this.currentDrawingId) {
                    const payload = {
                        geo_points: this.geoPoints
                    }
                    await $updateDrawing(this.currentDrawingId, payload)
                }
                // Else create drawing with new geoPoints
                else {
                    const res = await this.saveItems()
                    this.setItems(res.data.modified_items)
                }
            } catch (e) {
                console.log(e)
            }
        },
        showGeoMap() {
            this.isGeoSetMode = false
            this.isGeoDrawingMode = false
            this.calculateMapCenter()
            this.createGeoPoints()
            this.isGeoMapMode = true
        },
        showGeoSetMode() {
            this.isGeoMapMode = false
            this.isGeoDrawingMode = false
            this.isGeoSetMode = !this.isGeoSetMode
        },
        showGeoDrawingMode() {
            this.isGeoMapMode = false
            this.isGeoSetMode = false
            this.calculateMapCenter()
            this.createGeoPoints()
            this.isGeoDrawingMode = true
        },
        hideGeoDrawingMode() {
            this.isGeoDrawingMode = false
            this.setZoom(this.initialZoom || 1)
        },

        /**
         * Calculates the center of the Geo Map based on geoPoints.
         * If this.mapCenter is empty, has a length different from 2, or is [0, 0], it recalculates the center.
         * If this.mapCenter has a length of 2 and is not [0, 0], it does nothing.
         */
        calculateMapCenter() {
            let sumLat = 0
            let sumLon = 0

            // Sum all latitudes and longitudes from geoPoints
            this.geoPoints.forEach(point => {
                sumLat += point.lat
                sumLon += point.lon
            })

            // Calculate the average latitude and longitude
            const avgLat = sumLat / this.geoPoints.length
            const avgLon = sumLon / this.geoPoints.length

            // Set the mapCenter to the calculated average values
            this.mapCenter = [avgLon, avgLat]
        },

        createGeoPoints() {
            updateItemsLonLat(this.geoPoints, this.items)

            const pdfWidth = this.width / this.scale.x
            const pdfHeight = this.height / this.scale.y
            const pdfCorners = [{x: 0, y:0}, {x: pdfWidth, y:0}, {x: pdfWidth, y: pdfHeight}, {x: 0, y: pdfHeight}]
            updateItemsLonLat(this.geoPoints, pdfCorners)

            this.pdfCornersGeo = pdfCorners
        },

        async addItem(e: any) {
            const pos = e.target.getStage().getPointerPosition()

            const initialX = (pos.x - e.currentTarget.attrs.x) / this.scale.x
            const initialY = (pos.y - e.currentTarget.attrs.y) / this.scale.y

            const pdfWidth = this.width / this.scale.x
            const pdfHeight = this.height / this.scale.y
            const x = initialX < 0 ? undefined : (initialX > pdfWidth ? undefined : initialX)
            const y = initialY < 0 ? undefined : (initialY > pdfHeight ? undefined : initialY)

            if (!x || !y) {
                useSnackbarStore().showMessage({
                    color: 'warning',
                    icon: 'mdi-alert-circle',
                    title: 'Warning',
                    text: `Please add items only within the available area of the map, not outside.`
                })
                return
            }

            if (this.selectedBtn === 'sign') {
                await this.addSign(e)
            }
            else if (this.selectedBtn === 'kop') {
                await this.addKopItem(e)
            }
            else {
                const drawingItem = addDrawingItem(
                  this.selectedBtn,
                  x,
                  y,
                  this.isEditKopItemTypeShape,
                  this.initialZoom || 1
                )
                this.items.push(drawingItem)
                this.drawingItem = drawingItem

                if (this.offlineMode) {
                    const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

                    this.offlineItems.push({ ...drawingItem, location: location })
                }

                // Check if the selected button is 'text' and the new drawing item has a valid ID
                if (this.selectedBtn === 'text' && drawingItem?.id) {
                    // Select newly added Text Item by its ID
                    this.asyncSelectDrawingItemById(drawingItem.id)
                }
            }
        },

        async addKopItem(e: any) {
            if (!this.selectedKopItemType) return

            const id = uuidv4()
            const label_id = uuidv4()
            const pos = e.target.getStage().getPointerPosition()
            const x = (pos.x - e.currentTarget.attrs.x) / this.scale.x - (this.selectedKopItemType.shape_width / 2)
            const y = (pos.y - e.currentTarget.attrs.y) / this.scale.y - (this.selectedKopItemType.shape_height / 2)

            const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

            const kopItem = {
                ...kopItemData(id, x, y, this.selectedKopItemType),
                campaign: this.project.id,
                location: location,
                kop_item_type: this.selectedKopItemType.id,
            }
            this.items.push(kopItem)

            await this.createKopItem(id, x, y)
            // Add labels on create if needed
            // const label = labelData(
            //   label_id,
            //   x + (this.selectedKopItemType.shape_width / 2),
            //   y + this.selectedKopItemType.shape_height, id)
            // this.items.push(label)
        },

        async createKopItem(id: string, x: number, y: number) {
            const index = this.items.findIndex(i => i.id === id)
            try {
                const newItem = this.items[index]

                const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

                const kopItemPayload: KopItemPayload = {
                    campaign: this.project.id,
                    location: location,
                    kop_item_type: this.selectedKopItemType.id,
                    // TODO: Future updates - Update FA on create Kop Item with selected FA
                    // functional_area: this.selectedFA.id,
                    is_removed: false,
                    top: y,
                    left: x
                }

                const { data: kopItem } = await $createKopItem(kopItemPayload)

                newItem.kop_item = kopItem.id
                newItem.kop_item_status = kopItem.kop_item_status
                newItem.kop_item_type = kopItem.kop_item_type
                newItem.kop_item_functional_area = kopItem.kop_item_functional_area
                newItem.kop_item_code = kopItem.kop_item_code
                newItem.kop_item_short_code = kopItem.kop_item_short_code
                newItem.kop_item_qty = kopItem.kop_item_qty

                await this.saveItems()
            } catch (e) {
                console.log(e)
            }
        },

        async addSign(e: any) {
            if (!this.selectedSignType) return
            const id = uuidv4()
            const label_id = uuidv4()
            const pos = e.target.getStage().getPointerPosition()
            const x = (pos.x - e.currentTarget.attrs.x) / this.scale.x - 12
            const y = (pos.y - e.currentTarget.attrs.y) / this.scale.y - 12

            const { width, height } = await loadImage(this.selectedSignType.source)
            const ratio = width / height

            const location = this.selectedLocation ? this.selectedLocation.id : this.project.location
            const signName = this.createSignName(this.selectedSignType.trigram, this.selectedSignType.id)
            const offlineSignData = {
                ...signData(id, x, y, this.selectedSignType, 25/ratio), campaign: this.project.id,
                location: location,
                sign_type: this.selectedSignType.id,
                is_removed: false,
                top: y,
                left: x,
                sign_additional_functional_areas: [],
                sign_qty: 1,
                sign_type_colour: this.selectedSignType.colour,
                is_custom: this.selectedSignType.is_custom,
                sign_short_code: signName,
                sign_short_name: signName,
                orientation: 0,
                sign_status_name: 'Draft Dot Maps',
                notes: '',
                client_notes: '',
                fabric_notes: '',
                instruction: '',
                sign: id,
                responsible_name: '',
                assigned_to_name: '',
                is_can_user_approve: false,
                is_released: false,
            }

            // Add the new item to the drawing
            this.items.push(offlineSignData)
            const offlineLabelData = labelData(label_id, x + 10, y + 25, id)
            this.items.push(offlineLabelData)

            if (!this.offlineMode) {
                // Create sign
                await this.createSign(id, x, y, x + 10, y + 25, offlineSignData)
            }
            else {
                this.offlineSigns.push(offlineSignData)
                this.offlineSignLabels.push(offlineLabelData)
            }
        },

        createSignName(trigram: string, signId: number) {
            const maxCount = Math.max(...this.items
              .filter(item => item?.type === 'sign')?.map(item => item.sign_short_code?.split('-')[1]), 0) + 1
            const formattedMaxCount = String(maxCount).padStart(4, '0')
            return `${trigram}-${formattedMaxCount}`
        },

        async createSign(id: string, x: number, y: number, labelX: number, labelY: number, drawingSignData: any) {
            try {
                const location = this.selectedLocation ? this.selectedLocation.id : this.project.location

                const { newlyCreatedItems: unsavedItems, changedItems } = this.getNewAndChangedItems()

                const signPayload: SignPayload = {
                    campaign: this.project.id,
                    location: location,
                    type: this.selectedSignType.id,
                    is_removed: false,
                    top: y,
                    left: x,
                    label_top: labelY,
                    label_left: labelX,
                    // Drawing Sign Data to create sign + create new drawing with newly added sign and return
                    drawing_sign_data: JSON.stringify({
                        width: drawingSignData.width,
                        height: drawingSignData.height,
                    }),
                    // Changed and not saved items for data consistency
                    updated_drawing_items: JSON.stringify(changedItems),
                    // Unsaved drawing items to save before Sign Create for data consistency
                    unsaved_drawing_items: JSON.stringify(unsavedItems)
                }

                if (!this.selectedSignType.is_custom) {
                    signPayload.width = this.selectedSignType.width
                    signPayload.height = this.selectedSignType.height
                }

                // return Actual Drawing with newly added Sign
                const res = await $createSign(signPayload)

                // Update current items with new Drawing Items
                this.setItems(res.data.modified_items)
                // Update current Drawing ID
                this.updateCurrentDrawingId(res.data.id)

                // Update initial items
                this.updateInitialItems(res.data.modified_items)
            } catch (e: any) {
                // Log any errors that occur during the request
                console.log(e)
                const mes = e?.response?.data ?
                  (e.response.data.detail ? e.response.data.detail : JSON.stringify(e.response.data)) :
                  e.message
                useSnackbarStore().showMessage({
                    color: 'error',
                    icon: 'mdi-alert-circle',
                    title: 'Error',
                    text: `Oops, something went wrong. ${mes}`
                })

                // Remove newly added sign and sign label when error occurred
                this.items = this.items.filter(item => id !== item.id && !(item.type === 'label' && id === item.parent))
            }
        },

        async getAllDrawingItems() {
            try {
                const res = await $getCampaignLastDrawings(this.project.id)
                this.allDefaultDrawingItems = transformDrawingsArrayToObj(res.data)
            } catch (e) {
                console.log(e)
            }
        },

        // Function to revoke all object URLs
        revokeAllOfflineModeObjectUrls() {
            this.offlineModeObjectUrls.forEach(url => URL.revokeObjectURL(url))
            this.offlineModeObjectUrls.length = 0
        },

        clearOfflineItems() {
            // Filter out items that are present in offlineSigns/offlineItems based on id or parent id
            this.items = this.items.filter(item =>
              !this.offlineSigns.some(i => i.id === item.id || i.id === item.parent) &&
              !this.offlineItems.some(i => i.id === item.id)
            )

            // Find items with a 'file' property that starts with 'blob:' indicating a blob URL
            const itemsWithBlob = this.items.filter(i => i.file && i.file.startsWith('blob:'))
            // Filter out items that are present in itemsWithBlob based on id or parent id
            this.items = this.items.filter(item =>
              !itemsWithBlob.some(i => i.id === item.id || i.id === item.parent)
            )

            // Clear the offlineSigns and offlineItems array(s)
            this.offlineItems = []
            this.offlineSigns = []
            this.offlineSignLabels = []
        },

        // Update selectedSignType to match an item in signTypes by ID, or set to null if not found
        updateSelectedSignType() {
            if (this.selectedSignType?.id) {
                // Find the signType in signTypes array with the same ID as selectedSignType
                // If found, update selectedSignType, otherwise set to null
                this.selectedSignType = this.signTypes.find((i: any) => i.id === this.selectedSignType?.id) || null
            }
        },

        // Update selectedLocation to match an item in locations by ID, or set to null if not found
        updateSelectedLocation() {
            // Check if selectedLocation has a valid id
            if (this.selectedLocation?.id) {
                // Find the location in locations array with the same ID as selectedLocation
                // If found, update selectedLocation with the found location, otherwise set to null
                this.selectedLocation = getLocationById(this.locations, this.selectedLocation?.id)
            }
            // Else - select main location
            else {
                this.selectedLocation = JSON.parse(JSON.stringify(this.locations)) // deep copy
            }
        },

        async turnOnOfflineMode() {
            saveDefaultSignTypesToLocalStorage(this.signTypes)
            saveDefaultLocationsToLocalStorage(this.locations)

            // Fetch and store images for sign types
            this.signTypes = await getSignTypesWithImg(this.signTypes)
            // Fetch and store images recursively for locations
            this.locations = await getUpdatedLocationImageUrlRecursively(this.locations)

            // Update selectedSignType with new signTypes(with base64Img)
            this.updateSelectedSignType()
            // Update selectedLocation with new locations(with base64Img)
            this.updateSelectedLocation()

            await this.getAllDrawingItems()
        },
        async turnOffOfflineMode() {
            this.signTypes = loadDefaultSignTypesFromLocalStorage()
            this.locations = loadDefaultLocationsFromLocalStorage()

            // Update selectedSignType with default signTypes(with s3 url)
            this.updateSelectedSignType()
            // Update selectedLocation with default locations(with s3 url)
            this.updateSelectedLocation()

            this.clearOfflineItems()
            await idbClearAllImages()
            this.revokeAllOfflineModeObjectUrls()
        },

        async handleOfflineMode() {
            this.handleOfflineModeLoading = true

            if (!this.offlineMode) {
                await this.turnOnOfflineMode()
            } else {
                await this.turnOffOfflineMode()
            }
            this.offlineMode = !this.offlineMode

            this.handleOfflineModeLoading = false
        },

        async uploadOfflineItems() {
            this.uploadOfflineSignsLoading = true

            // Prepare the payload object to send in the request
            const payload = {
                campaign: this.project.id, // ID of the current project/campaign
                offline_signs: JSON.stringify(this.offlineSigns), // Convert offline signs array to JSON string
                offline_items: JSON.stringify(this.offlineItems) // Convert offline items array to JSON string
            }

            try {
                // Send the payload to create offline signs and wait for the response
                const res = await $createOfflineItems(payload)

                this.allDefaultDrawingItems = transformDrawingsArrayToObj(res.data)

                // Update the items with the modified items returned in the response
                this.setItems(this.allDefaultDrawingItems[this.selectedLocation.id] ?? [])

                // Clear the offline signs array after successful creation
                this.offlineItems = []
                this.offlineSigns = []
                this.offlineSignLabels = []

                useSnackbarStore().showMessage({
                    color: 'success',
                    icon: 'mdi-checkbox-marked-circle',
                    title: 'Success',
                    text: 'Offline signs uploaded successfully!'
                })
            } catch (e: any) {
                // Log any errors that occur during the request
                console.log(e)
                const mes = e.response.data ?
                  (e.response.data.detail ? e.response.data.detail : JSON.stringify(e.response.data)) :
                  e.message
                useSnackbarStore().showMessage({
                    color: 'error',
                    icon: 'mdi-alert-circle',
                    title: 'Error',
                    text: `Oops, something went wrong. ${mes}`
                })
            } finally {
                this.uploadOfflineSignsLoading = false
            }
        },

        selectItem(e: any, isCustomSelect = false) {
            // isCustomSelect - Select item by code
            const item = isCustomSelect ? this.findItemBySign(e) : this.findItemById(e.target)
            if (item.type !== 'label') {
                if (isCustomSelect || !e.evt.shiftKey) {
                    this.selectedItems = [item]
                } else {
                    this.selectedItems.push(item)
                }
                this.selectedItem = item
            }
        },
        unselectItem() {
            this.selectedItem = null
            this.selectedItems = []
        },
        handleDragStart(e: any) {},
        handleDragEnd(e: any) {
            this.updateItemPosition(e.target)
            this.updateOfflineItemPosition(e.target)
            if(e.target?.attrs?.tag === 'anchor') {
                this.updateLineAnchor(e)
            }
        },
        updateDrawingItem(e: any) {
            const pos = e.target.getStage().getPointerPosition()
            const x = (pos.x - e.currentTarget.attrs.x) / this.scale.x
            const y = (pos.y - e.currentTarget.attrs.y) / this.scale.y

            if (this.drawingItem) {
                const { updatedItem, updatedOfflineItems } = updateDrawingItem(
                  this.selectedBtn,
                  this.drawingItem,
                  x,
                  y,
                  this.offlineMode,
                  this.offlineItems,
                  this.isEditKopItemTypeShape,
                  this.initialZoom || 1
                )
                this.drawingItem = updatedItem
                this.items.splice(this.items.length - 1, 1, this.drawingItem)

                if (this.offlineMode) {
                    this.offlineItems = updatedOfflineItems
                }
            }
        },
        async handleStageClick(e: any) {
            this.isDrawing = true
            await this.addItem(e)
        },
        selectArea(e: any) {
            this.selectedItems = []
            this.isDrawing = true
            const pos = e.target.getStage().getPointerPosition()

            const x = (pos.x - e.currentTarget.attrs.x) / this.scale.x
            const y = (pos.y - e.currentTarget.attrs.y) / this.scale.y
            if (this.selectedBtn === 'move') {
                this.selectedArea = {
                    type: 'rect',
                    x: x,
                    y: y,
                    fill: 'rgba(0,0,255,0.1)',
                    opacity: 1,
                    strokeWidth: 1,
                    width: 0,
                    height: 0,
                }
                this.items.push(this.selectedArea)
            }
        },
        isItemInsideSelectedArea(item: any) {
            const selectedAreaX = this.selectedArea.width >= 0 ? this.selectedArea.x : this.selectedArea.x + this.selectedArea.width
            const selectedAreaY = this.selectedArea.height >= 0 ? this.selectedArea.y : this.selectedArea.y + this.selectedArea.height
            const selectedAreaWidth = Math.abs(this.selectedArea.width)
            const selectedAreaHeight = Math.abs(this.selectedArea.height)

            if (item.type === 'label') {
                return false
            }

            if (item.type === 'line') {
                const points = item.points
                for (let i = 0; i < points.length; i += 2) {
                    const pointX = points[i]
                    const pointY = points[i + 1]

                    if (
                        !(
                            pointX >= selectedAreaX &&
                            pointX <= selectedAreaX + selectedAreaWidth &&
                            pointY >= selectedAreaY &&
                            pointY <= selectedAreaY + selectedAreaHeight
                        )
                    ) {
                        return false // If at least one point does not fall within the area, return false
                    }
                }

                return true // If all points fall within the area, return true
            }
            if (item.type === 'text') {
                return (
                  item.x < selectedAreaX + selectedAreaWidth &&
                  item.x > selectedAreaX &&
                  item.y < selectedAreaY + selectedAreaHeight &&
                  item.y > selectedAreaY
                )
            }
            if (item.type === 'circle') {
                const circleCenterX = item.x
                const circleCenterY = item.y
                const circleRadius = item.radius

                const closestX = Math.max(Math.min(circleCenterX, selectedAreaX + selectedAreaWidth), selectedAreaX)
                const closestY = Math.max(Math.min(circleCenterY, selectedAreaY + selectedAreaHeight), selectedAreaY)

                return (
                    circleCenterX >= selectedAreaX &&
                    circleCenterX <= selectedAreaX + selectedAreaWidth &&
                    circleCenterY >= selectedAreaY &&
                    circleCenterY <= selectedAreaY + selectedAreaHeight &&
                    Math.pow(circleCenterX - closestX, 2) + Math.pow(circleCenterY - closestY, 2) < Math.pow(circleRadius, 2)
                )
            }
            if (item.type === 'kop_item') {
                const scaleX = item.scaleX || 1
                const scaleY = item.scaleY || 1
                return (
                  item.x < selectedAreaX + selectedAreaWidth &&
                  item.x + (item.width * scaleX) > selectedAreaX &&
                  item.y < selectedAreaY + selectedAreaHeight &&
                  item.y + (item.height * scaleY) > selectedAreaY
                )
            }
            return (
                item.x < selectedAreaX + selectedAreaWidth &&
                item.x + item.width > selectedAreaX &&
                item.y < selectedAreaY + selectedAreaHeight &&
                item.y + item.height > selectedAreaY
            )
        },
        handleStageMouseMove(e: any) {
            if(!this.isDrawing) return
            if(this.selectedBtn === 'move') {
                const pos = e.target.getStage().getPointerPosition()

                const endX = (pos.x - e.currentTarget.attrs.x) / this.scale.x
                const endY = (pos.y - e.currentTarget.attrs.y) / this.scale.y

                this.selectedArea.width = endX - this.selectedArea.x
                this.selectedArea.height = endY - this.selectedArea.y
                this.items.splice(this.items.length - 1, 1, { ...this.selectedArea})
            }
            this.updateDrawingItem(e)
        },
        handleStageMouseUp() {
            if(this.selectedBtn === 'move' && this.selectedArea) {
                this.items.forEach((item, index) => {
                    if(index !== this.items.length - 1 && this.isItemInsideSelectedArea(item)) {
                        this.selectedItems.push(item)
                    }
                    if(this.selectedItems.length === 1) this.selectedItem = this.selectedItems[0]
                })
                this.items.splice(this.items.length - 1, 1)
            }
            this.isDrawing = false
            this.drawingItem = null
            this.selectedArea = null

        },
        handleTransformed(e: any) {
            // Checking and limiting the boundaries of the PDF area
            const node = e.target
            // Get the current stage instance
            const currentStage = this.stage?.getStage()

            const box = node.getClientRect()

            const absPos = node.getAbsolutePosition()
            const offsetX = box.x - absPos.x
            const offsetY = box.y - absPos.y

            const newAbsPos = {...absPos}
            if (box.x - currentStage.x() < 0) {
                newAbsPos.x = -offsetX + currentStage.x()
            }
            if (box.y - currentStage.y() < 0) {
                newAbsPos.y = -offsetY + currentStage.y()
            }
            if (box.x - currentStage.x() + box.width > this.width) {
                newAbsPos.x = this.width - box.width - offsetX + currentStage.x()
            }
            if (box.y - currentStage.y() + box.height > this.height) {
                newAbsPos.y = this.height - box.height - offsetY + currentStage.y()
            }
            node.setAbsolutePosition(newAbsPos)

            // Find and update item data
            const item = this.findItemById(node)

            item.x = node.x()
            item.y = node.y()
            item.rotation = node.rotation()
            item.scaleX = node.scaleX()
            item.scaleY = node.scaleY()

            if (['sign', 'kop_item'].includes(item?.type)) {
                item.tagX = item.x + 10
                item.tagY = item.y + 25
            }
        },
        lockMoving() {
            this.items.forEach(item => { if(!!item) { item.draggable = this.isMoveMode } })
            if (this.selectedItems) this.selectedItems.forEach((item: any) => item.draggable = this.isMoveMode)
            if (this.selectedItem) this.selectedItem.draggable = this.isMoveMode
        },

        // HISTORY (UNDO/REDO)
        // Save drawingHistory
        saveHistory(changedItems: { id: number, x: number, y: number }[]) {
            // Create a snapshot with only id, x, and y
            const snapshot = changedItems.map(item => ({
                id: item.id,
                x: item.x,
                y: item.y
            }))

            // Step 1: Remove any forward history
            this.drawingHistory = this.drawingHistory.slice(0, this.drawingHistoryStep)

            // Step 2: Add the new snapshot of changed items to the history
            this.drawingHistory.push(snapshot)

            // Step 3: Update the history step
            this.drawingHistoryStep += 1

            // Step 4: If history exceeds 30 steps, remove the oldest snapshot
            if (this.drawingHistory.length > 30) {
                this.drawingHistory.shift()  // Remove the first element
                this.drawingHistoryStep -= 1 // Adjust history step to account for the removed snapshot
            }
        },

        // Undo action
        undo() {
            // Step 1: Only allow undo if we're not at the first history step
            if (this.drawingHistoryStep > 0) {
                // Step 2: Update the history index to reflect the undo
                this.drawingHistoryStep -= 1

                // Move one step back in the history
                const previousState = this.drawingHistory[this.drawingHistoryStep]

                // Step 3: Apply the previous state to the current items
                previousState.forEach(savedItem => {
                    const currentItem = this.items.find(item => item.id === savedItem.id)
                    if (currentItem) {
                        currentItem.x = savedItem.x
                        currentItem.y = savedItem.y
                    }
                })
            }
        },

        // Redo action
        redo() {
            // Step 1: Only allow redo if we're not at the latest point in history
            if (this.drawingHistoryStep < this.drawingHistory.length - 1) {
                // Step 2: Update the history index to reflect the redo
                this.drawingHistoryStep += 1

                // Move one step forward in the history
                const nextState = this.drawingHistory[this.drawingHistoryStep]

                // Step 3: Apply the next state to the current items
                nextState.forEach(savedItem => {
                    const currentItem = this.items.find(item => item.id === savedItem.id)
                    if (currentItem) {
                        currentItem.x = savedItem.x
                        currentItem.y = savedItem.y
                    }
                })
            }
        }
    }
})
