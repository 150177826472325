// Define a function to get a location by its ID
import type { WayfindingLocation } from '@/models/wayfinding/location'

export const getLocationById = (location: WayfindingLocation, id: number | string): WayfindingLocation | null => {
  // Check if the current object's ID matches the target ID
  if (String(location.id) === String(id)) {
    return location
  }

  // If the current object has children, search recursively
  if (location.children && location.children.length > 0) {
    for (const child of location.children) {
      const result = getLocationById(child, id)
      if (result) {
        return result
      }
    }
  }

  // If no match is found, return null
  return null
}
