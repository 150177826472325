<script setup lang="ts">
import { $getBrandingCampaignsWithOrdersSimpleList, $getCampaignSimpleList } from '@/api/campaigns'
import { uniqBy } from 'lodash'

interface IProps {
  modelValue?: any,
  label?: string,
  type?: any,
  workOrdersIncluded?: boolean,
  eventGroup?: any,
  selectOptions?: any[] | null
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => [],
  label: 'Project',
  type: null,
  workOrdersIncluded: false,
  eventGroup: null,
  selectOptions: null,
})

interface CustomEvents {
  'update:modelValue': (newValue: any) => void;
}

const emit = defineEmits<CustomEvents>()

interface Campaign {
  id: number
  name: string
}

const campaigns = ref<Campaign[]>([])
const campaignsCurrentPage = ref<number>(1)
const campaignsPerPage: number = 10
const campaignsSearchStr = ref<string>('')
const isNextCampaignsPageExist = ref<boolean>(false)
const campaignsLoading = ref<boolean>(false)

const campaignOptions = computed(() => {
  if (props.selectOptions) {
    return props.selectOptions
  } else {
    return campaigns.value
  }
})

const getCampaigns = async () => {
  campaignsLoading.value = true
  const filters = {
    // Minimum number of fields('id', 'name')
    is_select: true,
    type: props.type, // 1 - Wayfinding, 2 - Branding
    is_visible: true,
    // Filter by Event Group(Program)
    event_group_or_children: props.eventGroup && props.eventGroup.id ? props.eventGroup.id : undefined,

    search: campaignsSearchStr.value,
    per_page: campaignsPerPage,
    page: campaignsCurrentPage.value,
  }
  try {
    let apiCall
    if (props.workOrdersIncluded) {
      apiCall = $getBrandingCampaignsWithOrdersSimpleList
    } else {
      apiCall = $getCampaignSimpleList
    }

    const { data, count, isNextPageExist, error, loading } = await apiCall(filters)

    // Append the new campaigns to the existing list
    // Use Lodash's uniqBy to merge arrays while ensuring uniqueness based on 'id'
    campaigns.value = uniqBy([...campaigns.value, ...data.value], 'id')

    isNextCampaignsPageExist.value = !!isNextPageExist.value
  }
  catch (e) {
    console.log(e)
  }
  finally {
    campaignsLoading.value = false
  }
}

const onFocusCampaignSelect = async () => {
  if (!campaigns.value.length && !props.selectOptions) {
    campaigns.value = []
    campaignsCurrentPage.value = 1
    await getCampaigns()
  }
}

const onLoadMoreCampaigns = async () => {
  campaignsCurrentPage.value++ // Increment the current page to load the next page of campaigns
  await getCampaigns()
}

const clearAndGetCampaigns = (searchStr: string) => {
  campaigns.value = []
  campaignsCurrentPage.value = 1 // Set current page => 1 to load initial first page of campaigns
  campaignsSearchStr.value = searchStr
  getCampaigns()
}

const handleCampaignSearch = useDebounceFn((searchStr: string) => {
  if (!props.selectOptions) {
    clearAndGetCampaigns(searchStr)
  }
}, 500)

const onChildSelect = (val: number[], item: any) => {
  const modelValueCopy = JSON.parse(JSON.stringify(props.modelValue))
  if (modelValueCopy.find((i: Campaign) => i.id === item.value)) {
    modelValueCopy.find((i: Campaign) => i.id === item.value).selectedWorkOrders = val
    emit('update:modelValue', modelValueCopy)
  }
}

const getChildModelValue = (item: any) => {
  if (props.modelValue.find((i: Campaign) => i.id === item.value)) {
    return props.modelValue.find((i: Campaign) => i.id === item.value).selectedWorkOrders
  }
  return []
}

watch(() => props.type, async () => {
  clearAndGetCampaigns('')
})

defineExpose({ clearAndGetCampaigns })
</script>

<template>
  <VAutocomplete
    :model-value="modelValue"
    :items="[...campaignOptions]"
    return-object
    item-title="name"
    item-value="id"
    item-children="work_orders"
    :label="label"
    density="compact"
    :no-filter="!selectOptions"
    multiple
    clearable
    @update:modelValue="newValue => $emit('update:modelValue', newValue)"
    @update:focused="onFocusCampaignSelect"
    @update:search="handleCampaignSearch"
  >
    <template
      v-if="workOrdersIncluded"
      #item="{ item, index, props }"
    >
      <VListItem v-bind="props">
        <template #prepend="{ isActive }">
          <VCheckboxBtn :model-value="isActive" />
        </template>
        <template #title="{ title }">

        </template>
        <template #default="{ isSelected }">
          <div :class="{ 'mt-2' : item?.children?.length && isSelected }">
            {{ item.title }}
          </div>
          <div v-if="isSelected && item?.children?.length">
            <VList
              :selected="getChildModelValue(item)"
              :items="item.children"
              item-title="id"
              item-value="name"
              select-strategy="classic"
              @click.stop
              @update:selected="(val) => onChildSelect(val, item)"
            >
              <template v-slot:prepend="{ isActive }">
                <VListItemAction start>
                  <VCheckboxBtn :model-value="isActive"></VCheckboxBtn>
                </VListItemAction>
              </template>
            </VList>
          </div>
        </template>
      </VListItem>
    </template>
    <template #selection="{ item, index }">
      <div class="d-flex align-center">
        <v-chip v-if="index < 1">
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 1"
          class="text-grey text-caption align-self-center"
        >
          (+{{ modelValue?.length - 1 }} others)
        </span>
      </div>
    </template>
    <template #no-data>
      <div
        v-if="!!campaignsLoading"
        class="d-flex justify-center align-center"
      >
        Loading...
        <VProgressCircular
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </div>
      <div
        v-else
        class="d-flex justify-center align-center"
      >
        Projects not found
      </div>
    </template>
    <template
      v-if="isNextCampaignsPageExist"
      #append-item
    >
      <VBtn
        color="default"
        block
        :disabled="!!campaignsLoading"
        @click="onLoadMoreCampaigns"
      >
        Load more...
        <VProgressCircular
          v-if="!!campaignsLoading"
          class="margin-left-5"
          size="22"
          indeterminate
          color="white"
        />
      </VBtn>
    </template>
  </VAutocomplete>
</template>

<style lang="scss">
.v-list-item__prepend{
  min-height: 100% !important;
  display: flex;
  justify-content: start;
  align-items: start;
}
</style>
