import axios from 'axios'
import type { WayfindingLocation } from '@/models/wayfinding/location'
import { idbGetImageUrl, idbSaveImage } from '@/store/helpers/offlineItems/indexedDB'

// LocalStorage helpers
export function saveDefaultSignTypesToLocalStorage(signTypes: any[]): void {
  localStorage.setItem('defaultSignTypes', JSON.stringify(signTypes))
}

export function loadDefaultSignTypesFromLocalStorage(): any[] {
  const signTypes = localStorage.getItem('defaultSignTypes')
  return signTypes ? JSON.parse(signTypes) : []
}

export function saveDefaultLocationsToLocalStorage(locations: any): void {
  localStorage.setItem('defaultLocations', JSON.stringify(locations))
}

export function loadDefaultLocationsFromLocalStorage(): any[] {
  const locations = localStorage.getItem('defaultLocations')
  return locations ? JSON.parse(locations) : {}
}

async function getBase64ImgUrlFromUrl(imageUrl: string): Promise<string | null> {
  try {
    // Check if image is already cached in IndexedDB
    const cachedImageUrl = await idbGetImageUrl(imageUrl)
    if (cachedImageUrl) {
      return cachedImageUrl
    }

    // Fetch image from URL
    const response = await axios.get(imageUrl, {
      responseType: 'blob'
    })

    const blob = await response.data

    // Save image Blob in IndexedDB
    await idbSaveImage(imageUrl, blob)

    // Return the URL for the Blob stored in IndexedDB
    return await idbGetImageUrl(imageUrl)
  } catch (error) {
    console.error(`Error fetching image from URL: ${imageUrl}`, error)
    return null
  }
}

// Function to update signTypes with image URLs
export async function getSignTypesWithImg(signTypes: any[]): Promise<any[]> {
  return await Promise.all(
    signTypes.map(async (item: any) => {
      if (item.source) {
        const imgUrl = await getBase64ImgUrlFromUrl(item.source)
        return {
          ...item,
          source: imgUrl
        }
      }
      return item
    })
  )
}

// Function to update a single location's image URL
async function updateLocationImageUrl(location: WayfindingLocation): Promise<WayfindingLocation> {
  if (location.modified_image) {
    location.modified_image = await getBase64ImgUrlFromUrl(location.modified_image) as string
  }
  return location
}

// Recursive function to update images in a location and its children
export async function getUpdatedLocationImageUrlRecursively(location: WayfindingLocation): Promise<WayfindingLocation> {
  // Update the current location's image URL
  const updatedLocation = await updateLocationImageUrl(location)

  // If the location has children, recursively update their image URLs
  if (updatedLocation.children && updatedLocation.children.length > 0) {
    updatedLocation.children = await Promise.all(
      updatedLocation.children.map(getUpdatedLocationImageUrlRecursively)
    )
  }

  return updatedLocation
}
