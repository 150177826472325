import '@/@iconify/icons-bundle'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import {createApp} from 'vue'
import {abilitiesPlugin, Can} from '@casl/vue'
import * as Sentry from "@sentry/vue";
import App from '@/App.vue'
import VueKonva from 'vue-konva'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import {loadFonts} from '@/plugins/webfontloader'
import router from '@/router'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import ability from '@/plugins/casl/ability'
import jsPDF from 'jspdf'

import VueApexCharts from 'vue3-apexcharts'

loadFonts()

// Create vue app
const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// Use plugins
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(VueKonva, { prefix: 'k' })
app.use(jsPDF)
app.use(VueApexCharts)

// CASL
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
})
app.component('Can', Can)

Sentry.init({
    app,
    dsn: "https://1d72adc943282fb821246b89d5757f07@o4504411567357952.ingest.us.sentry.io/4507822162903040",
  });

// Mount vue app
app.mount('#app')
