<script setup lang="ts">
import { avatarText } from '@core/utils/formatters'
import { ActivityTimelineItem, TaggedUser } from '@/models/activity-timeline'

interface IProps {
  activityTimelineItems?: ActivityTimelineItem[],
  loading?: boolean,
}

const props = withDefaults(defineProps<IProps>(), {
  activityTimelineItems: () => ([]),
  loading: false,
})

// Function to highlight tagged users in activityTimelineItem content
const highlightTaggedUsers = (content: string, taggedUsers: TaggedUser[], historyId: string): string => {
  // Replace tagged users with highlighted format
  taggedUsers.forEach(taggedUser => {
    content = content.replaceAll(
      `@${taggedUser.id}`,
      `<span class="highlight-tag-user tag-user-${historyId}-${taggedUser.id}">@${taggedUser.first_name} ${taggedUser.last_name}</span>`
    )
  })
  return content
}
</script>

<template>
  <VCard
    title="Sign Activity"
    class="position-relative"
  >
    <VOverlay
      :model-value="loading"
      persistent
      absolute
      contained
      class="align-center justify-center"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VOverlay>

    <VCardText>
      <VTimeline
        side="end"
        align="start"
        truncate-line="both"
        density="compact"
        class="v-timeline-density-compact"
      >
        <VTimelineItem
          v-for="(activityTimelineItem, activityTimelineItemKey) in props.activityTimelineItems"
          :key="activityTimelineItemKey"
          :dot-color="activityTimelineItem.colour"
          size="x-small"
          class="sign-activity-timeline-item"
        >
          <!-- 👉 Header -->
          <span class="sign-activity-timeline-item__time app-timeline-meta">
            {{ activityTimelineItem.activity_time }}
          </span>
          <div class="d-flex justify-start align-center mb-1">
            <VAvatar
              class="me-1 pa-2 text-body-2 cursor-pointer"
              size="x-small"
              color="primary"
            >
              <span>
                {{
                  `${activityTimelineItem?.activity_user?.first_name?.slice(0, 1)}${activityTimelineItem?.activity_user?.last_name?.slice(0, 1)}`
                }}
                <VMenu
                  activator="parent"
                  location="top center"
                  open-on-hover
                  open-delay="100"
                  close-delay="0"
                  offset="5"
                  class="default-tooltip"
                >
                  <div class="mx-2">
                    <p class="mb-0 text-body-1 text-center">
                      {{ activityTimelineItem?.activity_user?.first_name }}
                      {{ activityTimelineItem?.activity_user?.last_name }}
                    </p>
                    <p class="mb-0 text-body-2 text-center">
                      {{ activityTimelineItem?.activity_user?.event_role }}
                    </p>
                  </div>
                </VMenu>
              </span>
            </VAvatar>
            <span class="app-timeline-title">
              {{ activityTimelineItem.title }}
            </span>
          </div>

          <!-- 👉 Content -->
          <div class="app-timeline-text activity-time-line-item-content mb-2" style="white-space: pre-line">
            <div
              v-if="activityTimelineItem && activityTimelineItem.activity_type === 'comment' &&
                    activityTimelineItem.tagged_users && activityTimelineItem.tagged_users.length"
            >
              <div
                v-html="highlightTaggedUsers(activityTimelineItem.content, activityTimelineItem.tagged_users,
                                             activityTimelineItem.history_id)"
              />
              <VMenu
                v-for="(taggedUser, taggedUserKey) in activityTimelineItem.tagged_users"
                :key="taggedUserKey"
                :activator="`.tag-user-${activityTimelineItem.history_id}-${taggedUser.id}`"
                location="top center"
                open-on-hover
                open-delay="100"
                close-delay="0"
                offset="5"
                class="default-tooltip"
              >
                <span class="px-2 text-center">
                  {{ taggedUser.email }}
                </span>
              </VMenu>
            </div>
            <div v-else>
              {{ activityTimelineItem?.content }}
            </div>
          </div>
        </VTimelineItem>
      </VTimeline>
    </VCardText>
  </VCard>
</template>

<style lang="scss">
.line-height-normal {
  line-height: normal;
}

.highlight-tag-user {
  cursor: pointer;
  color: rgb(var(--v-theme-primary));
  &:hover {
    text-decoration: underline;
  }
}

.sign-activity-timeline-item {
  .v-timeline-item__body {
    position: relative;
    .sign-activity-timeline-item__time {
      position: absolute;
      top: -7px;
      right: 0;
    }
    .activity-time-line-item-content {
      word-wrap: break-word; /* Allows long words to be broken and wrap to the next line */
      hyphens: auto; /* Enables hyphenation if supported by the browser */
    }
  }
}
</style>
