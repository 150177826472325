<script setup>
import { VDataTableVirtual } from 'vuetify/labs/VDataTable'
import { $getSignHistory } from '@/api/signs'

const props = defineProps({
  modelValue: Boolean,
  sign: { type: Object, default: () => ({}) }
})

const emit = defineEmits(['update:modelValue', 'onClose'])

const historyRecords = ref([])
const historyRecordsLoading = ref(false)

const tableHeaders = [
  { key: 'sign_status_name', title: 'Status', sortable: false },
  { key: 'previous_status', title: 'Previous Status', sortable: false },
  { key: 'next_status', title: 'Next Status', sortable: false },
  { key: 'change_reason', title: 'Type', sortable: false },
  { key: 'user_name', title: 'User Name', sortable: false },
  { key: 'change_date', title: 'Date/Time', sortable: false },
  { key: 'responsible_name', title: 'Next Action (Responsible)', sortable: false },
]

const getHistoryRecords = async () => {
  historyRecordsLoading.value = true
  const filters = {
    id: props.sign?.id,
  }
  try {
    const res = await $getSignHistory(filters)
    historyRecords.value = res.data
  } catch (e) {
    console.log(e)
  } finally {
    historyRecordsLoading.value = false
  }
}

const closeDialog = () => {
  emit('update:modelValue', !props.modelValue)
}

const onClose = async () => {
  emit('onClose')
}

watch(() => props.modelValue, () => {
  if (props.modelValue) {
    getHistoryRecords()
  }
})
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="1200"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard>
      <template #title>
        Sign Status History
      </template>
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            class="py-0 mt-1"
          >
            <VDataTableVirtual
              :headers="tableHeaders"
              :items="historyRecords"
              :loading="historyRecordsLoading"
              no-data-text="History records not found"
              hover
              density="compact"
            />
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>
