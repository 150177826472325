import {defineStore} from 'pinia'
import type { UserData } from '@/models/user'

export const useUserStore = defineStore({
    id: 'user',
    persist: true,
    state: () => ({
        user: {} as UserData,
        auth_token: '',
    }),
    getters: {
        userId: state => state.user.id,
        isLoggedIn: state => state.auth_token !== '',
        shortName: state => `${state.user.first_name?.slice(0, 1)}${state.user.last_name?.slice(0, 1)}`,
        userRole: state => state.user.user_role,
        isCustomer: state => state.user.user_role === 2,
        isSupplier: state => state.user.user_role === 3,
        isAdmin: state => state.user.user_role === 0,
        isWayfindingCampaignAccess: state => state.user?.campaign_access?.wayfinding,
        isBrandingCampaignAccess: state => state.user?.campaign_access?.branding,
        isKopCampaignAccess: state => state.user?.campaign_access?.kop,
        isWayfindingCampaignCreateAccess: state => state.user?.campaign_create_access?.wayfinding,
        isBrandingCampaignCreateAccess: state => state.user?.campaign_create_access?.branding,
        isKopCampaignCreateAccess: state => state.user?.campaign_create_access?.kop,
    },
    actions: {
        saveUser(user: UserData) {
            this.user = user
        },

        saveToken(token: string) {
            this.auth_token = token
        },

        userLogout() {
            this.auth_token = ''
            this.user = {}
            localStorage.removeItem('userAbilities')
        },
    },
})

// Define the type of the store
export type UserStore = ReturnType<typeof useUserStore>
