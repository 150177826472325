interface ImageDimensions {
  width: number;
  height: number;
}

export const loadImage = (src: string): Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const newImg = new Image()

    newImg.onload = () => {
      const width = newImg.width
      const height = newImg.height
      resolve({ width, height })
    }

    newImg.onerror = () => {
      // Resolve with default dimensions on error
      resolve({ width: 25, height: 25 })
    }

    newImg.src = src
  })
}
