import axiosIns from '@axios'
import {reactive, ref, toRefs} from "vue";

const $http = axiosIns

export const $userList = async (filters: object) => {
  return await $http.get('/users/', { params: filters })
}

export const $userView = async (id: any) => {
  return await $http.get(`/users/${id}`)
}

export const $userCreate = async (payload: object) => {
  return await $http.post('/users/', payload)
}

export const $userUpdate = async (id: any, payload: object) => {
  return await $http.patch(`/users/${id}/`, payload)
}

export const $userOptions = async () => {
  return await $http.options('/users/')
}

export const $userSettingsUpdate = async (id: any, payload: object) => {
  return await $http.patch(`/user/settings/${id}/`, payload)
}

// Custom Event Roles
export const $customEventRoleList = async (filters: object) => {
  return await $http.get('/custom-event-roles/', { params: filters })
}

// Customers
export const $customerList = async (params: object) => {
  return await $http.get('/customers-full/', { params })
}

export const $getCustomer = async (id: any) => {
  return await $http.get(`/customer/${id}`)
}

export const $createCustomer = async (payload: object) => {
  return await $http.post('/customer-create/', payload)
}

export const $updateCustomer = async (id: any, payload: object) => {
  return await $http.patch(`/customer/${id}`, payload)
}

export const $getCustomers = async (params: object) => {
  const data = ref([])
  const count = ref(0)
  const isNextPage = ref(false)

  const state = reactive({
    error: null,
    loading: false,
  })

  try {
    state.loading = true

    const res = await $http.get('customers-full/', { params })

    data.value = res.data.results
    count.value = res.data.count
    isNextPage.value = !!res.data.next
  }
  catch (e: any) {
    state.error = e
  }
  finally {
    state.loading = false
  }

  return { data, count, isNextPage, ...toRefs(state) }
}

// Suppliers
export const $supplierList = async (filters: object) => {
  return await $http.get('/suppliers/', { params: filters })
}

export const $createSupplier = async (payload: object) => {
  return await $http.post('/suppliers/', payload)
}

export const $deleteSupplier = async (id: number) => {
  return await $http.delete(`/suppliers/${id}/`)
}

export const $updateSupplier = async (id: number, payload: object) => {
  return await $http.patch(`/suppliers/${id}/`, payload)
}

export const $supplierOptions = async () => {
  return await $http.options('/suppliers/')
}

// Permissions
export const $getUserPermissionList = async (filters: object) => {
  return await $http.get('/user-permissions/', { params: filters })
}
