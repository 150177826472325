import axiosIns from '@axios'

const $http = axiosIns

export const $getItems = async (params: object) => {
  return await $http.get('items/', { params })
}

export const $getUserItems = async (params: object) => {
  return await $http.get('line-items-user/', { params })
}

export const $getItemOptions = async () => {
  return await $http.options('items/')
}

export const $createItem = async (payload: object) => {
  return await $http.post('items/', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $updateItem = async (id: number | string, payload: object) => {
  return await $http.patch(`items/${id}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $deleteItem = async (id: number | string) => {
  return await $http.delete(`items/${id}/`)
}

export const $getItemTypes = async (params: object) => {
  return await $http.get('item-types', { params })
}

export const $createItemType = async (payload: object) => {
  return await $http.post('item-types/', payload)
}

export const $updateItemType = async (id: number | string, payload: object) => {
  return await $http.patch(`item-types/${id}/`, payload)
}

export const $deleteItemType = async (id: number | string) => {
  return await $http.delete(`item-types/${id}/`)
}
