import {Ability} from '@casl/ability'
import type {UserAbility} from './AppAbility'

// Base ability with common permissions for all roles
export const initialAbility: UserAbility[] = [
    {action: 'view', subject: 'Auth'},
    {action: 'view', subject: 'initial'},
]

// Customer-specific abilities (extends baseAbilities)
export const customerAbilities: UserAbility[] = [
    ...initialAbility,
    {action: 'view', subject: 'campaign'},
]

// Admin-specific abilities (extends baseAbilities)
export const adminAbilities: UserAbility[] = [
    ...initialAbility,
    {action: 'add', subject: 'campaign'},
    {action: 'view', subject: 'campaign'},
    {action: 'change', subject: 'campaign'},
    {action: 'add', subject: 'location'},
    {action: 'view', subject: 'location'},
    {action: 'change', subject: 'location'},
    {action: 'view', subject: 'booking'},
    {action: 'change', subject: 'booking'},
    {action: 'add', subject: 'user'},
    {action: 'change', subject: 'user'},
    {action: 'view', subject: 'user'},
    {action: 'view', subject: 'settings'},
    {action: 'add', subject: 'settings'},
    {action: 'change', subject: 'settings'},
]

//  Read ability from localStorage
// 👉 Handles auto fetching previous abilities if already logged in user
// ℹ️ You can change this if you store user abilities to more secure place
// ❗ Anyone can change localStorage so be careful and please change this
const stringifiedUserAbilities = localStorage.getItem('userAbilities')
const existingAbility = stringifiedUserAbilities ? JSON.parse(stringifiedUserAbilities) : null

export default new Ability(existingAbility || initialAbility)
