<script lang="ts" setup>
import { useDrawingStore } from '@/store/drawing'
import {
  $addSignBlockPictogram,
  $createMultipleSignBlocks,
  $updateMultipleSignBlocks,
  $updateSignBlock,
  $updateSignBlockPictogram
} from '@/api/signs'
import Draggable from 'vuedraggable'
import SignBlockEditDialog from '@/components/projects/signs/sign-block/SignBlockEditDialog.vue'
import { useUserStore } from '@/store/user'
import { can } from '@layouts/plugins/casl'
import ability from '@/plugins/casl/ability'
import { useSnackbarStore } from '@/store/snackbar'

interface IProps {
  sign?: any,
  signSides?: number,
  glossaryItemsBySide?: any,
  isDrawing?: boolean,
  sides?: string[],
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  signSides: 1,
  glossaryItemsBySide: () => ({}),
  isDrawing: false,
  sides: () => (['A', 'B', 'C', 'D']),
})

interface Emit {
  (e: 'handleSignBlockData', value: any): void
  (e: 'getSignBlocks'): void
  (e: 'getActivityTimelineItems'): void
}

const emit = defineEmits<Emit>()

const drawingStore = useDrawingStore()
const userStore = useUserStore()
const snackbarStore = useSnackbarStore()

const alignmentOptions = [
  { value: 1, icon: 'tabler-align-left' },
  { value: 2, icon: 'tabler-align-center' },
  { value: 3, icon: 'tabler-align-right' }
]

const showGlossaryItemDialog = ref<boolean>(false)
const glossaryItemDialogIsPictogramOnly = ref<boolean>(false)
const glossaryItemDialogIsAddPictogram = ref<boolean>(false)
const glossaryItemDialogIsAddPictogramItem = ref<boolean>(false)
const glossaryItemDialogSelectedSignBlockId = ref<number | null>(null)
const glossaryItemDialogSelectedSignBlockPictogramId = ref<number | null>(null)

const showSignBlockEditDialog = ref<boolean>(false)
const selectedSignBlock = ref({})

const currentTab = ref(0)

const tabs = computed(() => {
  const result = []

  if (props.sign && props.sign.type_info) {
    for (let i = 0; i <= props.sign.type_info.sides; i++) {
      result.push(i)
    }
  }

  return result
})

const isShowGlossaryBlock = computed(() => {
  if (props.isDrawing) {
    return !drawingStore.offlineMode
  }
  return true
})

const addSignBlock = (isPictogram = false) => {
  showGlossaryItemDialog.value = true
  if (isPictogram) {
    glossaryItemDialogIsAddPictogramItem.value = true
  }
}

const updateSignBlockPictogram = async (selectedGlossaryItemIds: number[], isDeletePictogram = false) => {
  const [glossaryItemId] = selectedGlossaryItemIds
  const selectedSignBlockPictogramId = glossaryItemDialogSelectedSignBlockPictogramId.value

  // If no selectedSignBlockPictogramId - return
  if (!selectedSignBlockPictogramId) {
    return
  }

  const payload = {
    glossary_item_id: glossaryItemId,
    is_delete: isDeletePictogram,
  }
  try {
    // return Sign
    const res = await $updateSignBlockPictogram(selectedSignBlockPictogramId, payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}
const createSignBlocks = async (glossaryItemsIds: number[]) => {
  const payload = {
    sign: props.sign.id,
    side_number: currentTab.value,
    glossary_items_ids: glossaryItemsIds
  }
  try {
    // return Sign
    const res = await $createMultipleSignBlocks(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

// Update Sign Blocks position numbers
const updateSignBlocksOrder = async () => {
  const signBlocks = props.glossaryItemsBySide[currentTab.value]
    .map((i: any, Idx: number) => ({id: i.id, position_number: Idx}))
  try {
    // return Sign(from the first SignBlock sent)
    const res = await $updateMultipleSignBlocks(signBlocks)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const handleSignBlockData = (data: any) => {
  emit('handleSignBlockData', data)
  emit('getActivityTimelineItems')
}

const onCloseGlossaryItemDialog = () => {
  glossaryItemDialogIsPictogramOnly.value = false
  glossaryItemDialogIsAddPictogram.value = false
  glossaryItemDialogIsAddPictogramItem.value = false
  glossaryItemDialogSelectedSignBlockId.value = null
  glossaryItemDialogSelectedSignBlockPictogramId.value = null
}

const onClickSignBlockPictogram = (signBlockPictogramId: number) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    showGlossaryItemDialog.value = true
    glossaryItemDialogIsPictogramOnly.value = true
    // change
    glossaryItemDialogIsAddPictogram.value = false
    // Pictogram ID
    glossaryItemDialogSelectedSignBlockPictogramId.value = signBlockPictogramId
  }
}

const onAddSignBlockPictogram = (signBlockId: number) => {
  if (!userStore.isCustomer && ability.can('change', 'sign')) {
    showGlossaryItemDialog.value = true
    glossaryItemDialogIsPictogramOnly.value = true
    // add
    glossaryItemDialogIsAddPictogram.value = true
    // Glossary Block ID
    glossaryItemDialogSelectedSignBlockId.value = signBlockId
  }
}

const addSignBlockPictogram = async (selectedGlossaryItemIds: number[]) => {
  const [glossaryItemId] = selectedGlossaryItemIds
  const signBlockId = glossaryItemDialogSelectedSignBlockId.value

  // If no signBlockId - return
  if (!signBlockId) {
    return
  }

  const payload = {
    sign_block_id: signBlockId,
    glossary_item_id: glossaryItemId,
  }
  try {
    // return Sign
    const res = await $addSignBlockPictogram(payload)
    handleSignBlockData(res.data)
  } catch (e) {
    console.log(e)
  }
}

const onSelectAlignment = async (signBlockId: number, alignmentValue: number) => {
  try {
    await $updateSignBlock(signBlockId, { alignment: alignmentValue })
  } catch (e) {
    console.log(e)
  }
}
</script>

<template>
  <div v-if="isShowGlossaryBlock">
    <GlossaryItemDialog
      v-model="showGlossaryItemDialog"
      :glossary-group-id="sign?.campaign_glossary_group_id || null"
      :glossary-pictogram-catalogue-id="sign?.campaign_glossary_pictogram_catalogue_id || null"
      :is-add-pictogram="!!glossaryItemDialogIsAddPictogram"
      :is-add-pictogram-item="!!glossaryItemDialogIsAddPictogramItem"
      :is-pictogram-only="!!glossaryItemDialogIsPictogramOnly"
      @onClose="onCloseGlossaryItemDialog"
      @onCreateSignBlocks="createSignBlocks($event)"
      @onAddPictogram="addSignBlockPictogram($event)"
      @onDeletePictogram="updateSignBlockPictogram([], true)"
      @onUpdatePictogram="updateSignBlockPictogram($event)"
    />
    <SignBlockEditDialog
      v-model="showSignBlockEditDialog"
      :sign-block="selectedSignBlock"
      @onSubmit="emit('getSignBlocks'); emit('getActivityTimelineItems')"
    />
    <div
      v-if="!userStore.isCustomer && can('change', 'sign')"
      class="d-flex align-center flex-wrap justify-end"
    >
      <VBtn
        size="small"
        class="ma-1"
        @click="addSignBlock(true)"
      >
        <VIcon icon="mdi-add"/>
        Add Pictogram
      </VBtn>
      <VBtn
        size="small"
        class="ma-1"
        @click="addSignBlock(false)"
      >
        <VIcon icon="mdi-add"/>
        Add Glossary Item
      </VBtn>
    </div>
    <VTabs
      v-model="currentTab"
      bg-color="transparent"
      grow
    >
      <VTab
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        Side {{ sides[tabIndex] }}
      </VTab>
    </VTabs>

    <VWindow v-model="currentTab">
      <VWindowItem
        v-for="tabIndex in tabs"
        :key="tabIndex"
        :value="tabIndex"
      >
        <VCard
          flat
        >
          <VCardText v-if="glossaryItemsBySide[tabIndex].length">
            <Draggable
              :list="glossaryItemsBySide[tabIndex]"
              ghost-class="ghost-draggable"
              item-key="id"
              @change="updateSignBlocksOrder"
            >
              <template #item="{ element }">
                <div class="d-flex align-center justify-space-between border pa-2 cursor-pointer flex-wrap">
<!--                  <VBtnToggle-->
<!--                    v-model="element.alignment"-->
<!--                    class="d-flex py-2"-->
<!--                    color="primary"-->
<!--                    mandatory-->
<!--                    :rounded="false"-->
<!--                    divided-->
<!--                    @update:model-value="newVal => onSelectAlignment(element.id, newVal)"-->
<!--                  >-->
<!--                    <VBtn-->
<!--                      v-for="(item, itemKey) in alignmentOptions"-->
<!--                      :key="itemKey"-->
<!--                      :value="item.value"-->
<!--                      size=""-->
<!--                      class="px-1"-->
<!--                      variant="outlined"-->
<!--                    >-->
<!--                      <VIcon :icon="item.icon" />-->
<!--                    </VBtn>-->
<!--                  </VBtnToggle>-->
                  <div class="pa-1 pa-sm-0">
                    {{ element.text_1 }}
                  </div>
                  <div class="pa-1 pa-sm-0">
                    {{ element.text_2 }}
                  </div>
                  <div class="d-flex align-center flex-wrap">
                    <div
                      v-for="(pictogram, pictogramKey) in element.pictograms"
                      :key="pictogramKey"
                      class="position-relative pa-1 pa-sm-0"
                    >
                      <VAvatar
                        :image="pictogram.pic"
                        class="mx-3"
                        rounded
                        size="50"
                        @click.stop="onClickSignBlockPictogram(pictogram.id)"
                      />
                      <VMenu
                        v-if="!userStore.isCustomer && can('change', 'sign')"
                        activator="parent"
                        class="default-tooltip"
                        close-delay="0"
                        location="top"
                        offset="5"
                        open-delay="100"
                        open-on-hover
                      >
                        <span>
                          Update pictogram
                        </span>
                      </VMenu>
                    </div>
                    <VSheet
                      :height="50"
                      :width="50"
                      border
                      class="add-pictogram-block mr-3 d-flex align-center justify-center"
                      rounded
                      @click.stop="onAddSignBlockPictogram(element.id)"
                    >
                      <VIcon
                        v-if="!userStore.isCustomer && can('change', 'sign')"
                        icon="mdi-plus"
                      />
                    </VSheet>
                    <VBtn
                      v-if="!userStore.isCustomer && can('change', 'sign')"
                      class="rounded-lg pa-1 pa-sm-0 mx-auto"
                      density="compact"
                      icon="tabler-edit"
                      variant="elevated"
                      @click="showSignBlockEditDialog = true; selectedSignBlock = { ...element }"
                    />
                  </div>
                </div>
              </template>
            </Draggable>
          </VCardText>
          <VCardText
            v-else
            class="text-center"
          >
            Glossary Items not found
          </VCardText>
        </VCard>
      </VWindowItem>
    </VWindow>
  </div>
</template>

<style scoped>

</style>
