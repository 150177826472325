<script setup>
import VuePdfEmbed from 'vue-pdf-embed'
import { useDrawingStore } from '@/store/drawing'
import {
  $approveSign,
  $getSign,
  $signOptions,
  $getSignActivityTimeline,
  $updateSign,
} from '@/api/signs'
import { useUserStore } from '@/store/user'
import ScopingPhotosBlock from '@/components/projects/signs/sign-modal/ScopingPhotosBlock.vue'
import { $getCampaign } from '@/api/campaigns'
import { useSnackbarStore } from '@/store/snackbar'
import SignDialogNotesBlock from "@/components/projects/signs/sign-modal/SignDialogNotesBlock.vue";

const router = useRouter()
const route = useRoute()

const signActivityTimelineSidebar = ref()

const dialogVisible = ref(false)
const drawingStore = useDrawingStore()
const userStore = useUserStore()
const snackbarStore = useSnackbarStore()

const sign = ref({})
const signPriorityOptions = ref([])
const props = defineProps({
  signId: Number
})
const emit = defineEmits(['sign-updated'])
const project = ref({})
const signLoading = ref(false)

const approveBtnLoading = ref(false)
const rejectBtnLoading = ref(false)
const updateBtnLoading = ref(false)
const printBtnLoading = ref(false)

const signTypes = ref([])
const funcAreas = ref([])

const sideTab = ref(0)
const photoTab = ref(0)
const selectedPhotoType = ref(0)

const artworkPdf = ref()
const artworkLoading = ref(true)
const page = ref(1)
const totalPages = ref(1)
const pdfPages = [
  {value: 1, title: 'A'},
  {value: 2, title: 'B'},
  {value: 3, title: 'C'},
  {value: 4, title: 'D'}
]

const glossaryItemsBySide = ref({ 0: [], 1: [], 2: [], 3: []})

const showSignStatusHistoryDialog = ref(false)

const sides = ['A', 'B', 'C', 'D']
const signSides = ref(1)

const showSidebar = ref(false)
const signActivityTimelineItems = ref([])
const signActivityTimelineLoading = ref(false)

const showSignActivityQuery = computed(() => {
  return route.query['show-sign-activity']
})

const getSignOptions = async () => {
  try {
    const res = await $signOptions()
    signPriorityOptions.value = res.data.actions.POST.priority.choices
  } catch (e) {
    console.log(e)
  }
}

const onSelectPriority = async (priorityValue) => {
  if (priorityValue !== sign.value.priority) {
    signLoading.value = true
    try {
      const res = await $updateSign(sign.value.id, { priority: priorityValue })
      handleSignData(res.data)
      snackbarStore.showMessage({
        color: 'success',
        icon: 'mdi-checkbox-marked-circle',
        title: 'Success',
        text: 'Sign Priority updated successfully!'
      })
    } catch (e) {
      console.log(e)
      const mes = e.response.data ? e.response.data : e.message
      snackbarStore.showMessage({
        color: 'error',
        icon: 'mdi-alert-circle',
        title: 'Error',
        text: `Oops, something went wrong. ${mes}`
      })
    } finally {
      signLoading.value = false
    }
  }
}

const updateSignIsOnHold = async () => {
  signLoading.value = true
  try {
    const res = await $updateSign(sign.value.id, { is_on_hold: sign.value.is_on_hold })
    handleSignData(res.data)
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'On Hold option updated successfully!'
    })
  } catch (e) {
    console.log(e)
    const mes = e.response.data ? e.response.data : e.message
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: `Oops, something went wrong. ${mes}`
    })
  } finally {
    signLoading.value = false
  }
}

const handleSignBlockData = (data) => {
  signSides.value = data.type_info.sides

  sign.value.sign_blocks = data.sign_blocks

  glossaryItemsBySide.value = { 0: [], 1: [], 2: [], 3: [] }
  sign.value.sign_blocks.forEach((block) => {
    const sideNumber = block.side_number

    // Push the block to the corresponding array
    glossaryItemsBySide.value[sideNumber].push(block)
  })
}

const handleSignData = (data) => {
  sign.value = data

  sign.value.number = data.short_name

  if (!sign.value.width) {
    sign.value.width = data.type_info.width
  }

  if (!sign.value.height) {
    sign.value.height = data.type_info.height
  }

  sign.value.is_custom = data.type_info.is_custom

  handleSignBlockData(data)
  getSignActivityTimelineItems()
}

const getProject = async () => {
  const res = await $getCampaign(sign.value.campaign)
  project.value = res.data
  signTypes.value = res.data.sign_type_list
  funcAreas.value = res.data.functional_area_list
}

const getSign = async (updateSignBlocksOnly = false) => {
  signLoading.value = true
  try {
    if(props.signId) {
      const res = await $getSign(props.signId)
      if (updateSignBlocksOnly) {
        handleSignBlockData(res.data)
      } else {
        handleSignData(res.data)
      }
      await getProject()
    }
  } catch (e) {
    console.log(e)
  } finally {
    signLoading.value = false
  }
}

const updateSign = async () => {
  updateBtnLoading.value = true
  try {
    const res = await $updateSign(sign.value.id, sign.value)
    handleSignData(res.data)
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'Sign updated successfully!'
    })
  } catch (e) {
    console.log(e)
  } finally {
    updateBtnLoading.value = false
  }
}

const approveSign = async (isApprove = true) => {
  if (isApprove) {
    approveBtnLoading.value = true
  } else {
    rejectBtnLoading.value = true
  }
  try {
    await $approveSign(sign.value.id, { is_approve: isApprove })
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'Sign updated successfully!'
    })
    await getSign()
  } catch (e) {
    const mes = e.response.data ? e.response.data : e.message
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: `Oops, something went wrong. ${mes}`
    })
  } finally {
    if (isApprove) {
      approveBtnLoading.value = false
    } else {
      rejectBtnLoading.value = false
    }
  }
}

const rejectSign = async () => {
  await approveSign(false)
}

const onSignTypeChange = () => {
  const newSignType = signTypes.value.find(i => i.id === sign.value.type)
  if (newSignType && !newSignType.is_custom) {
    sign.value.is_custom = false
    sign.value.width = newSignType.width
    sign.value.height = newSignType.height
  }
  else if (newSignType && newSignType.is_custom) {
    sign.value.is_custom = true
  }
}

const onCloseDialog = () => {
  emit('sign-updated')
}

const handleArtworkRendered = () => {
  artworkLoading.value = false
  totalPages.value = artworkPdf.value.pageCount
}

const htmlPdfComponent = ref()

const generatePdf = () => {
  htmlPdfComponent.value.useGeneratePdf()
}

const getSignActivityTimelineItems = async () => {
  signActivityTimelineLoading.value = true
  try {
    const res = await $getSignActivityTimeline(props.signId)
    signActivityTimelineItems.value = res.data

    // Scroll sidebar to the bottom after next DOM update
    await nextTick(() => {
      signActivityTimelineSidebar.value?.scrollSidebarToBottom()
    })
  } catch (e) {
    console.log(e)
  } finally {
    signActivityTimelineLoading.value = false
  }
}

// TODO: commented due to performance. Now getSign() handle on update dialogVisible
// onMounted(() => getSign())

watch(() => props.signId, () => {
  getSign()
  getSignOptions()
})

// Reusable function to silently update the query without causing a page reload, onMounted and navigation guard methods
const updateQueryParameters = (updateFunction) => {
  // Create a copy of the route query parameters
  const newQuery = { ...route.query }

  // Call the provided update function to modify the query
  updateFunction(newQuery)

  // Get the current path without the query string
  const path = window.location.pathname

  // Build the new URL with the updated query parameters
  const newUrl = `${path}?${new URLSearchParams(newQuery).toString()}`

  // Replace the current state with the updated URL
  window.history.replaceState(null, '', newUrl)
}

// Function to delete the 'show-sign-activity' query parameter
const deleteShowSignActivityQuery = () => {
  updateQueryParameters((newQuery) => {
    delete newQuery['show-sign-activity']
  })
}

watch(dialogVisible, () => {
  // on show Dialog
  if (!!dialogVisible.value) {
    if (showSignActivityQuery.value === 'true') {
      showSidebar.value = true
      deleteShowSignActivityQuery()
    }
  }
  // on hide Dialog
  else {
  }
})
</script>

<template>
  <div>
    <SignStatusHistoryDialog
      v-model="showSignStatusHistoryDialog"
      :sign="sign"
    />
    <VDialog
      v-model="dialogVisible"
      class="sign-modal"
      width="920"
      persistent
      @update:model-value="getSign(false); getSignOptions()"
      @after-leave="onCloseDialog"
    >
      <template #activator="{ props }">
        <VBtn v-bind="props" size="small">
          View
        </VBtn>
      </template>

      <DialogCloseBtn
        @click="dialogVisible = !dialogVisible"
      />

      <VCard
        :loading="!!signLoading"
      >
        <template #title>
          <SignDialogTitle
            v-model:show-sidebar="showSidebar"
            :approve-btn-loading="!!approveBtnLoading"
            :reject-btn-loading="!!rejectBtnLoading"
            :sign="sign"
            @onApproveSign="approveSign"
            @onRejectSign="rejectSign"
          />
        </template>
        <SignDialogHeader
          v-model:sign="sign"
          :sign-loading="signLoading"
          :sign-priority-options="signPriorityOptions"
          @onSelectPriority="onSelectPriority"
          @onUpdateSignIsOnHold="updateSignIsOnHold"
        />
        <SignDialogResponsibleBlock
          :sign="sign"
        />
        <VRow>
          <VCol
            cols="12"
            md="7"
          >
            <VCardText>
              <VRow>
                <VCol
                  cols="12"
                  sm="6"
                >
                  <VLabel>Sign Code:</VLabel><br />
                  <b>{{ sign.short_name }}</b>
                </VCol>
                <VCol
                  cols="12"
                  sm="6"
                >
                  <VSelect
                    v-model="sign.type"
                    :items="signTypes"
                    :disabled="userStore.isCustomer"
                    item-title="name"
                    item-value="id"
                    label="Sign Type"
                    @update:model-value="onSignTypeChange"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="8"
                >
                  <VAutocomplete
                    v-model="sign.additional_functional_areas"
                    :items="funcAreas"
                    :disabled="userStore.isCustomer"
                    item-title="name"
                    item-value="id"
                    density="compact"
                    label="Functional Areas"
                    multiple
                    chips
                    closable-chips
                    no-data-text="Functional Areas not found"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        v-bind="props"
                        :text="item.raw.name"
                      />
                    </template>
                    <template v-slot:item="{ props, item }">
                      <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                      />
                    </template>
                  </VAutocomplete>
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VSelect
                    v-model="sign.orientation"
                    :items="[{value: 0, title: 'Portrait'}, { value: 1, title: 'Landscape'}]"
                    :disabled="userStore.isCustomer"
                    label="Orientation"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField type="number" v-model="sign.width" :disabled="!sign.is_custom || userStore.isCustomer" label="Width" />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField type="number" v-model="sign.height" :disabled="!sign.is_custom || userStore.isCustomer" label="Height" />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField type="number" v-model="sign.qty" :disabled="userStore.isCustomer" label="Qty" />
                </VCol>
              </VRow>
            </VCardText>
            <SignDialogNotesBlock
              v-model:sign="sign"
            />
          </VCol>
          <VCol
            cols="12"
            md="5"
          >
            <VTabs v-model="photoTab">
              <VTab>Artwork</VTab>
              <VTab>Photos</VTab>
            </VTabs>
            <VWindow v-model="photoTab">
              <VWindowItem class="pa-1 h-100">
                <template v-if="!sign.artwork_link">
                  <div class="pa-2 text-center">
                    No Artwork
                  </div>
                </template>
                <template v-else>
                  <VOverlay
                    v-model="artworkLoading"
                    class="align-center justify-center"
                    contained
                    persistent
                  >
                    <VProgressCircular
                      color="primary"
                      indeterminate
                    />
                  </VOverlay>
                  <VuePdfEmbed
                    ref="artworkPdf"
                    :page="page"
                    :source="sign.artwork_link"
                    @rendered="handleArtworkRendered"
                  />
                  <div class="pdf-pages">
                    <template v-for="side in signSides + 1">
                      <a :class="{ 'active': side  === page }"
                         class="pdf-page" @click="page = side"
                      >
                        {{ sides[side - 1] }}
                      </a>
                    </template>
                  </div>
                </template>
              </VWindowItem>
              <VWindowItem>
                <div
                  class="mt-2 pl-2 pl-md-0"
                  style="height: 510px; overflow: visible;"
                >
                  <ScopingPhotosBlock
                    :sign-id="props.signId"
                  />
                </div>
              </VWindowItem>
            </VWindow>
          </VCol>
        </VRow>
        <VContainer fluid>
          <VRow>
            <VCol>
              <SignDialogGlossaryBlock
                :sign="sign"
                :signSides="signSides"
                :glossaryItemsBySide="glossaryItemsBySide"
                :sides="sides"
                @handleSignBlockData="handleSignBlockData($event)"
                @getSignBlocks="getSign(true)"
                @getActivityTimelineItems="getSignActivityTimelineItems"
              />
            </VCol>
          </VRow>
        </VContainer>
        <VCardText>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <div>
              <VBtn
                class="mt-2 mt-sm-0"
                :disabled="!!updateBtnLoading"
                @click="updateSign"
              >
                Update
                <VProgressCircular
                  v-if="!!updateBtnLoading"
                  indeterminate
                  size="16"
                  width="2"
                  class="ml-1"
                  color="light"
                />
              </VBtn>
              <!--              <VBtn @click="drawingStore.deleteSelectedItem(); drawingStore.signDialogVisible = false;" variant="tonal" class="ml-2">Delete</VBtn>-->
            </div>
            <VBtn
              :disabled="!!printBtnLoading"
              color="default"
              variant="tonal"
              class="mt-2 mt-sm-0"
              @click="generatePdf"
            >
              Print
              <VProgressCircular
                v-if="!!printBtnLoading"
                class="ml-1"
                color="light"
                indeterminate
                size="16"
                width="2"
              />
            </VBtn>
          </div>
        </VCardText>
      </VCard>
      <!-- Sidebar -->
      <SignActivityTimelineSidebar
        ref="signActivityTimelineSidebar"
        v-model:show-sidebar="showSidebar"
        :activity-timeline-items="signActivityTimelineItems"
        :loading="signActivityTimelineLoading"
        :sign-id="props.signId"
        @onAdd="getSignActivityTimelineItems"
      />
    </VDialog>
    <SignDialogHtmlPdf
      ref="htmlPdfComponent"
      v-model:print-loading="printBtnLoading"
      :sign="sign"
      :activity-timeline-items="signActivityTimelineItems"
    />
  </div>
</template>

<style lang="scss">
.sign-modal {
  .v-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px !important;
  }

  .ghost-draggable {
    opacity: 0.5;
    background: rgb(var(--v-theme-primary), 0.2);
  }

  .add-pictogram-block {
    &:hover {
      background-color: rgb(var(--v-theme-on-surface), 0.1);
    }
  }
}
.pdf-item {
  padding: 24px;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: #fff;
  color: #000;
}
.pdf-pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .pdf-page {
    cursor: pointer;
    padding: 2px 8px;
    margin: 0 2px;
    border: 1px solid #DC0000;
    border-radius: 50%;
    &.active {
      background: #DC0000;
      color: #fff;
    }
  }
}
</style>
