<script setup lang="ts">
import { Sign } from '@/models/sign'
import { VDataTableVirtual } from 'vuetify/labs/VDataTable'

interface IProps {
  sign?: Sign,
  drawingMap?: boolean,
  dialogWidth?: number,
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  drawingMap: false,
  dialogWidth: 0,
})

interface CustomEvents {
}

const emit = defineEmits<CustomEvents>()

const colSize = computed<number>(() => {
  if (props.drawingMap) {
    return props.dialogWidth > 450 ? 6 : 12
  } else {
    // Access Vuetify display through $vuetify
    const instance = getCurrentInstance()
    const smAndUp = instance?.proxy?.$vuetify.display.smAndUp

    return smAndUp ? 6 : 12
  }
})
</script>

<template>
  <VCardText
    class="d-flex justify-space-between flex-wrap"
  >
    <VRow>
      <VCol
        :cols="colSize"
      >
        <template v-if="sign?.responsible_list?.length">
          <table>
            <tr
              v-for="(responsible, responsibleKey) in sign?.responsible_list"
              :key="responsibleKey"
            >
              <td
                v-if="responsibleKey === 0"
                :rowspan="sign?.responsible_list?.length"
              >
                <div class="d-flex flex-column">
                  Responsible:
                  <VBtn
                    v-if="sign?.approve_phases?.length"
                    color="light"
                    size="x-small"
                    class="px-1"
                  >
                    <VMenu
                      activator="parent"
                      location="top center"
                      open-on-hover
                      open-delay="100"
                      close-delay="0"
                      offset="5"
                      class="default-tooltip text-body-2"
                    >
                      <VDataTableVirtual
                        :headers="[
                              { key: 'responsible_approver_name', title: 'Approver', sortable: false },
                              { key: 'is_approved', title: 'Is Approved', sortable: false },
                            ]"
                        :items="[...sign?.approve_phases]"
                        no-data-text="Approve Info not found"
                        hover
                        density="compact"
                      >
                        <template #item.is_approved="{ item }">
                          <span v-if="item.raw.is_approved === true">
                            <VIcon
                              color="success"
                              icon="tabler-check"
                            />
                            by
                            {{ item.raw.actual_approver_name }}
                          </span>
                          <span v-else-if="item.raw.is_approved === false">
                            <VIcon
                              color="primary"
                              icon="tabler-x"
                            />
                            by
                            {{ item.raw.actual_approver_name }}
                          </span>
                          <span v-else>
                            <VIcon
                              color="secondary"
                              icon="tabler-minus"
                            />
                          </span>
                        </template>
                      </VDataTableVirtual>
                    </VMenu>
                    <VIcon
                      size="22"
                      icon="tabler-info-circle"
                    />
                  </VBtn>
                </div>
              </td>
              <td class="pl-1">
                {{ responsible?.first_name }} {{ responsible?.last_name }}
              </td>
            </tr>
          </table>
        </template>
        <template v-else>
          <span>
            Responsible:
            {{ sign?.responsible_name }}
          </span>
        </template>
      </VCol>
      <VCol
        :cols="colSize"
        class="d-flex align-center"
      >
        <span>
          Assigned To:
          {{ sign?.assigned_to_name }}
        </span>
      </VCol>
    </VRow>
  </VCardText>
</template>
