<script setup lang="ts">
import { Sign as SignInterface } from '@/models/sign'
import { useUserStore } from '@/store/user'

interface IProps {
  sign?: SignInterface,
  drawingMap?: boolean,
  dialogWidth?: number,
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  drawingMap: false,
  dialogWidth: 0,
})

interface Emit {
  (e: 'update:sign', value: SignInterface): void
}

const emit = defineEmits<Emit>()

const userStore = useUserStore()

const computedSign = computed({
  // getter
  get: () => props.sign || {},  // Provide a fallback in case props.sign is undefined
  // setter
  set: (newSign: SignInterface) => {
    emit('update:sign', newSign) // Emit update to parent when the local sign is changed
  }
})

const colSize = computed<number>(() => {
  if (props.drawingMap) {
    return props.dialogWidth > 450 ? 6 : 12
  } else {
    // Access Vuetify display through $vuetify
    const instance = getCurrentInstance()
    const smAndUp = instance?.proxy?.$vuetify.display.smAndUp

    return smAndUp ? 6 : 12
  }
})
</script>

<template>
  <VCardText>
    <VRow>
      <VCol
        :cols="colSize"
      >
        <VTextarea
          v-model="computedSign.notes"
          label="Supplier Notes"
          :disabled="userStore.isCustomer"
        />
      </VCol>
      <VCol
        :cols="colSize"
      >
        <VTextarea
          v-model="computedSign.client_notes"
          label="Client Notes"
          :disabled="!userStore.isCustomer"
        />
      </VCol>
      <VCol
        :cols="colSize"
      >
        <VTextarea
          v-model="computedSign.fabric_notes"
          label="Notes for Fabricator"
          :disabled="userStore.isCustomer"
        />
      </VCol>
      <VCol
        :cols="colSize"
      >
        <VTextarea
          v-model="computedSign.instruction"
          label="Installation Instructions"
          :disabled="userStore.isCustomer"
        />
      </VCol>
    </VRow>
  </VCardText>
</template>

<style lang="scss" scoped>
</style>
