<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { $getUserItems } from '@/api/items'

interface IProps {
  maxItemsCount?: number,
}

const props = withDefaults(defineProps<IProps>(), {
  maxItemsCount: 0,
})

const vuetifyDisplay = useDisplay()

const items = ref([])
const itemsLoading = ref(false)
const itemsCount = ref(0)
const currentPage = ref(1)
const nextPage = ref(null)
const previousPage = ref(null)

const perPage = computed<number>(() => {
  return vuetifyDisplay?.lgAndUp ? 20 : 10
})

const tableHeight = computed<number | string>(() => {
  if (vuetifyDisplay?.lgAndUp) {
    if (props.maxItemsCount === 0) {
      return 100
    }
    return 500
  }
  return ''
})

const getItems = async () => {
  itemsLoading.value = true
  try {
    const res = await $getUserItems({
      page: currentPage.value,
      per_page: perPage.value
    })

    items.value = res.data.results
    itemsCount.value = res.data.count
    nextPage.value = res.data.next
    previousPage.value = res.data.previous
  } catch (e) {
    console.log(e)
  } finally {
    itemsLoading.value = false
  }
}

onMounted(() => getItems())

// properties & methods are public
defineExpose({
  itemsCount,
})
</script>

<template>
  <VCard title="Branding Items Assigned To You">
    <VCardText>
      <VOverlay
        v-model="itemsLoading"
        persistent
        absolute
        contained
        class="align-center justify-center"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VOverlay>
      <VTable
        :fixed-header="$vuetify.display.lgAndUp"
        :height="tableHeight"
      >
        <thead>
          <tr>
            <th>Line No</th>
            <th>Status</th>
            <th>Project</th>
            <th>Work Order</th>
            <th>Description</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template
            v-for="item in items"
            :key="item.id"
          >
            <tr>
              <td>{{ item.line_no }}</td>
              <td>{{ item.status }}</td>
              <td>{{ item.campaign }}</td>
              <td>{{ item.work_order_no }}</td>
              <td>{{ item.description }}</td>
              <td>
                <VBtn
                  :to="`/projects/branding/${item.campaign_id}/work-orders/${item.work_order_id}/line-items/${item.id}`"
                  size="small"
                >
                  View
                </VBtn>
              </td>
            </tr>
          </template>
          <tr v-if="!items.length && !itemsLoading">
            <td colspan="6" class="text-center">Items not found</td>
          </tr>
        </tbody>
      </VTable>
      <div
        class="mt-2"
      >
        <VBtn
          size="small"
          class="mr-2"
          :disabled="!previousPage"
          @click="currentPage--; getItems()"
        >
          &lt;
        </VBtn>
        <VBtn
          size="small"
          :disabled="!nextPage"
          @click="currentPage++; getItems()"
        >
          >
        </VBtn>
      </div>
    </VCardText>
  </VCard>
</template>
